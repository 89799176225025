import { http, http2, Http2ErrorUnion } from 'api/http'
import { withFallback } from 'io-ts-types/lib/withFallback'
import * as settings from 'const/settings'
import moment from 'moment-timezone'
import * as t from 'io-ts'
import * as et from 'io-ts/Type'
import { Either } from 'fp-ts/lib/Either'
import { getCampaignRequestPath, getCampaignIdParams } from 'api/sharedUtils'
import {
  IInstitution,
  ILimitedInstitution,
  IInboxResponseData,
  IQuestionSearchResponseData,
  IUpdateProfileResponseData,
  IGetUsersByLocationResponseData,
  IUpdateUserLocationResponseData,
  IInstitutionWithInboxCountResponseData,
  IContactResponseData,
  IUserResponseData,
  IFetchKnowledgeSeedsResponseData,
  ICampaignScriptPreviewListResponse,
  ICampaignScriptRetrieveResponse,
  IImportLabelResponse,
  IConversationResponseData,
  IUploadStudentGroupResponseData,
  IUploadStudentGroupPollingResponseData,
  IActivityLogResponseData,
  IActivityLogTotalsResponseData,
  IPaginatedResponse,
  IActivityLogListResponseData,
  ICreateBotResponseData,
  IPhoneNumbersResponseData,
  ICampaignScriptResponse,
  ICampaignScriptPreviewResponse,
  ILoginResponse,
  IEnrollmentInfoResponseData,
  ICampaignDetailsResponseData,
  ICampaignReportPollResponse,
  IGenerateCampaignResponse,
  ICampaignHistoryResponseData,
  IUserPasswordRequirementInfo,
  IApplicationAlertBannerResponse,
  IListCustomLabelsResponse,
  IFetchAllFieldsResponseData,
  IFetchMappedFieldsResponseData,
  IPreprocessStudentUploadResponseData,
  IMostActiveContactResponseData,
  ISMSSettingsResponse,
  IAPIAuthToken,
  IAPIAuthTokenCreate,
  IInitializeFileUploadResponse,
  IUploadResponse,
  IWebBotSettingsResponse,
  ISearchUnderstandingByTopicResponseData,
  IUpdateUnderstandingTopLevelResponse,
  ICounselorResponseData,
  ICounselorsResponseData,
  IEscalationRulesResponseData,
  ICreateUnderstandingV2ResponseData,
  IGetAllInstitutionNamesResponseData,
  IEscalationRuleResponseData,
  IEscalationUnderstandingsResponseData,
  IInstitutionAttributesResponseData,
  IInstitutionAttributeRetrieveResponseData,
  IConfigureSalesforceResponse,
  ITemplateListResponse,
  IContactAttributesResponseData,
  IContactAttributeResponseData,
  ITraySolutionResponseData,
  ITraySolutionInstanceResponseData,
  ICreateSolutionInstanceResponseData,
  IUpdateSolutionInstanceResponseData,
  IIntegrationErrorsResponse,
  IFilteredContactsResponseData,
  IDialogSettings,
  IContactSegmentsListResponse,
  IContactFilterResponseData,
  ITopLevelContactFieldsResponseData,
  IInstitutionAttributeResponseData,
  IRecurringCampaignDetailsResponseData,
  IContactConditionGroupResponseData,
  IRecurringCampaignsListResponseData,
} from 'api/response'
import {
  IHandleLogRequestData,
  IUpdateProfileRequestData,
  IForwardToStaffRequestData,
  ISendSingleTextRequestData,
  IViewerListRequestData,
  IUpdateUserLocationRequestData,
  ICampaignSchedulerRequestData,
  ICreateContactRequestData,
  IActivityLogQueryStringParameters,
  IUpdateActivityLogRequest,
  ICampaignScriptStateSpliceRequest,
  ICampaignScriptStateDeleteEdgeRequest,
  ICampaignScriptStateUpdateRequest,
  ICampaignScriptUpdateRequest,
  ICreateBotRequestData,
  INewUserRequestData,
  IContactUploadRequestData,
  IUpdateSMSSettingsRequestData,
  IPurchasePhoneNumbersRequestData,
  IUpdateWebBotSettingsRequestData,
  IUpdateWebBotLockRequestData,
  IFetchContactLabelsRequestData,
  ICreateUpdateUnderstandingV2RequestData,
  ICounselorRequestData,
  IGettingSuggestedCounselorsRequestData,
  ISaveEscalationRuleRequestData,
  IEscalationPrioritizeRulesRequestData,
  IInstitutionAttributeRequestData,
  ISetContactAttributeRequestData,
  ICreateSolutionInstanceRequestData,
  IToggleSolutionInstanceEnabledRequestData,
  IContactFilterRequestData,
  IInstitutionContactAuthSettingsRequestData,
  IGetAttributesRequestData,
  IGetAttributeMappingsRequestData,
  ICreateNewAttributesFromMapperRequestData,
  IUpdateAttributeMappingRequestData,
  IInsightsRequestData,
  IInsightsReportRequestData,
  IInsightsReportPostRequestData,
} from 'api/request'
import { AxiosResponse, CancelToken, AxiosError, Params } from 'axios'
import {
  IContactNote,
  IContact,
  IFullContact,
  IContactLabel,
  IContactCounts,
  IArchiveActionType,
} from 'store/contacts/reducer'
import {
  IScheduledCampaign,
  DeleteRecurringCampaignTypeEnum,
} from 'store/scheduledCampaigns/reducer'
import {
  ICampaignScriptPreview,
  ICampaignScriptStep,
  PromptType,
  IIntroDialog,
  IntroDialogKind,
  ExitActionType,
  IVCard,
} from 'store/campaign-scripts/reducer'
import {
  IImportReport,
  IImportReportListOrdering,
} from 'store/import-reports/reducer'
import { IMPORT_REPORTS_PER_PAGE } from 'store/import-reports/selectors'
import { IUser } from 'store/triage/users/reducer'
import { SortBy, SortOrder, ICampaign } from 'store/campaign-history/reducer'

import { FeaturesType } from 'components/Feature/Feature'
import { IContactFormData } from 'components/ContactPanel/EditableContactPanel'
import { IDialogLabel } from 'store/dialog-labels/reducer'
import {
  IAttributeTypeCounter,
  InstitutionAttributeType,
} from 'store/personalization/institutionAttributes/selectors'
import {
  ContactAttributeType,
  IAttributeRef,
} from 'store/personalization/contactAttributes/selectors'
import { timeout } from 'util/timeout'
import { IMPORTED_RECIPIENTS_PER_PAGE } from 'store/campaign-scheduler/selectors'
import {
  HashtagCommand,
  HashtagPartialUpdateRequest,
  HashtagCommandUpdateRequest,
  HashtagCommandCreateRequest,
} from 'store/commands/commands-store'
import { fromEnum } from 'util/schema'
import { TransportId, TransportEnum } from 'store/transport'
import { SidebarFilter } from 'page/conversations-v2/ConversationList/actions'
import {
  KnowledgeReviewReason,
  KnowledgeReviewMark,
} from 'page/conversations-v2/ConversationsDetail/'
import { StatusFilter } from 'page/conversations-v2/ConversationList/ConversationHeader/ConversationListStatusFilter'
import { ConversationListFilterParams } from 'page/conversations-v2/ConversationList/Conversations/Conversations'
import { IFilterModalState } from 'components/FilterContactsModal/FilterContactsModal'
import {
  ChangedOccurrenceTypeEnum,
  IRecurrenceSettings,
} from 'store/campaign-scheduler/reducer'
import {
  ICreateAudienceFromSurveyResponses,
  IGenerateCampaignReportPayload,
} from 'store/campaign-details/thunks'
import { CombinationOperator } from 'components/ContactFilterBuilder/formUtils'
import { ComparisonOperator } from 'components/ContactFilterBuilder/SelectOperator'
import { IPreferencesRequestPayload } from 'components/TrendsV2/CustomizedDownloadModal'
import { IIntroDialogListItem } from 'components/IntroDialogList/IntroDialogList'
import {
  DashboardResponse,
  DashboardResponseType,
  KnowledgeMatchingCount,
  ReviewItemsShape,
  UnderstandingsCount,
  WebChatFeedBackStatisticsShape,
} from 'components/TrendsV2/types'
import { BotStatus } from 'store/institution/selectors'
import { RepeatInterval } from 'util/dateRepeatIntervals'

export interface IApiError {
  message: string
}

export const login = (email: string, password: string) => {
  return http.post<ILoginResponse>('/api/v0/auth/login/', { email, password })
}

export const logout = () => http.post<void>('/api/v0/auth/logout/')

export const changePassword = (
  oldPassword: string,
  newPassword1: string,
  newPassword2: string
) =>
  http.post<{ detail?: string }>('/api/v0/auth/change_password/', {
    old_password: oldPassword,
    new_password1: newPassword1,
    new_password2: newPassword2,
  })

export const oauthFBComplete = ({
  code,
  serverState,
  clientState,
}: {
  readonly code: string | string[] | undefined
  readonly serverState: string | string[] | undefined
  readonly clientState: string
}) =>
  http.post('/api/v0/oauth/fb/complete/', {
    code,
    server_state: serverState,
    client_state: clientState,
  })

export const getActivityLogs = (
  request: Partial<IActivityLogQueryStringParameters>
) =>
  http.get<IPaginatedResponse<IActivityLogListResponseData>>(
    `/api/v0/activity/`,
    {
      params: {
        ...request,
        limit: request.limit ? request.limit : 20,
      },
    }
  )

export const getActivityLogTotals = (
  filters?: Partial<IActivityLogQueryStringParameters>
) =>
  http.get<IActivityLogTotalsResponseData>(`/api/v0/activity/totals/`, {
    params: {
      ...filters,
    },
  })

export const getConversationTriage = (id: string) =>
  http.get<IConversationResponseData>(`/api/v0/conversations/${id}/`, {
    params: { triage: true, filter: '' },
  })

export const getMostActiveContacts = (limit = 10) =>
  http.get<IMostActiveContactResponseData[]>(
    '/api/v0/triage/contacts/most_active/',
    { params: { limit } }
  )

export const getInbox = (page: number = 1) =>
  http.get<IInboxResponseData>(`/api/v0/triage/inbox/`, {
    params: {
      limit: settings.TRIAGE_INBOX_ITEMS_PER_PAGE,
      offset: (page - 1) * settings.TRIAGE_INBOX_ITEMS_PER_PAGE,
    },
  })

// TODO: Create interfaces for log handling
export const handleLog = (id: string, data: IHandleLogRequestData) =>
  http.put(`/api/v0/triage/logs/${id}/handle/`, data)

export const createUnderstandingV2 = (
  understanding: ICreateUpdateUnderstandingV2RequestData
) =>
  http.post<ICreateUnderstandingV2ResponseData>(
    `/api/v0/understandings/`,
    understanding
  )

interface ICorrectAiLogResponse {
  readonly messageId: string
  readonly topic: string
}
export const correctAiLogResponse = ({
  messageId,
  topic,
}: ICorrectAiLogResponse) =>
  http.post(`/api/v0/conversations/${messageId}/correct_ai_response/`, {
    topic,
  })

interface IUpdateUnderstandingTopLevel {
  readonly id: string
  readonly aiLogMessageId: string | null
  readonly topic: string
  readonly sampleQuestion: string
}
/* Only edit the understanding topic and sample question, child objects like
answer groups and question groups must be edited through their specific
endpoints. */
export const updateUnderstandingTopLevel = (
  understanding: IUpdateUnderstandingTopLevel
) =>
  http.patch<IUpdateUnderstandingTopLevelResponse>(
    `/api/v0/understandings/${understanding.id}/top_level_fields/`,
    understanding
  )

export const forwardToStaff = (values: IForwardToStaffRequestData) =>
  http.post(`/api/v0/triage/logs/forward/`, values)

interface IForwardToCounselor {
  messageId: string
  counselorEmails: string[]
  forwardNote: string
  generateSuggestedReply: boolean
}
export const forwardToCounselor = (values: IForwardToCounselor) =>
  http.post(`/api/v0/conversations/forward/`, values)

export const getSuggestedCounselors = (
  data: IGettingSuggestedCounselorsRequestData
) => {
  return http.post<ICounselorResponseData[]>(
    `/api/v0/counselors/get_suggested_counselors/`,
    data
  )
}

export const searchQuestionsV2 = (
  data: { readonly question: string },
  cancelToken?: CancelToken
) =>
  http.post<{
    readonly results: ReadonlyArray<{
      readonly id: string
      readonly topic: string
      readonly matchingQuestion: string
      readonly answer: string
      readonly sampleQuestion: string
      readonly category: string
      readonly subCategory: string
    }>
  }>('/api/v0/triage/understanding/search/', data, { cancelToken })

export const searchQuestions = (data: { readonly question: string }) =>
  http.post<IQuestionSearchResponseData>(
    `/api/v0/triage/understanding/search/`,
    data
  )

/** This requires Admithub privileges to access
 * @deprecated this is slow (n+1). Only use if you need `count` in the response.
 */
export const getOrgsWithInboxCount = () =>
  http.get<IInstitutionWithInboxCountResponseData[]>(
    `/api/v0/triage/institution/`
  )

export type InstitutionListItem = {
  id: string
  displayName: string | null
  name: string
  oliName: string
  messagingService: string
  internal: boolean
  isActive: boolean
  botStatus: BotStatus
}

const InstitutionListItemShape = t.type({
  id: t.string,
  displayName: et.nullable(t.string),
  name: t.string,
  oliName: t.string,
  messagingService: t.string,
  internal: t.boolean,
  isActive: t.boolean,
  botStatus: fromEnum('BotStatus', BotStatus),
})

/** Fetch all institutions available for a user. */
export const getInstitutions = (copyingScript = false) =>
  http2({
    url: '/api/v0/institution/',
    params: { copyingScript },
    method: 'GET',
    shape: t.array(InstitutionListItemShape),
  })

/** Fetch list of all institutions for admithub user */
export const getAllInstitutionNames = () =>
  http.get<IGetAllInstitutionNamesResponseData>(
    `/api/v0/triage/institution/list_all_names/`
  )

/** Return the current institution for a user */
export const getCurrentInstitution = () =>
  http.get<IInstitution>('/api/v0/institution/current/')

export const getInstitution = (
  id: string | undefined = settings.CURRENT_INSTITUTION_LOOKUP_ID_ALT
) => http.get<ILimitedInstitution>(`/api/v0/institution/${id}/`)

export const buyPhoneNumbersForInstitution = ({
  id = settings.CURRENT_INSTITUTION_LOOKUP_ID,
  quantity,
  areaCode,
}: IPurchasePhoneNumbersRequestData) =>
  http.post<IPhoneNumbersResponseData>(
    `/api/v0/institution/${id}/buy_phone_numbers/`,
    { quantity, areaCode }
  )

interface IFeatureFlagForInstitutionRequestData {
  readonly id?: string
  readonly feature: FeaturesType
  readonly enable: boolean
}

export const updateFeatureFlagForInstitution = ({
  id = settings.CURRENT_INSTITUTION_LOOKUP_ID,
  feature,
  enable,
}: IFeatureFlagForInstitutionRequestData) =>
  http.post<IInstitution>(`/api/v0/institution/${id}/update_feature_flag/`, {
    feature,
    enable,
  })

type IDialogSettingsBooleanRequestData = {
  readonly id?: string
  readonly dialogSetting: keyof IDialogSettings
  readonly enabled: boolean
  readonly value?: undefined
}

type IDialogSettingsStringRequestData = {
  readonly id?: string
  readonly dialogSetting: keyof IDialogSettings
  readonly value: string
  readonly enabled?: undefined
}

type IDialogSettingsRequestData =
  | IDialogSettingsBooleanRequestData
  | IDialogSettingsStringRequestData

export const updateDialogSettings = ({
  id = settings.CURRENT_INSTITUTION_LOOKUP_ID,
  dialogSetting,
  enabled,
  value,
}: IDialogSettingsRequestData) =>
  http.post<IInstitution>(`/api/v0/institution/${id}/update_dialog_setting/`, {
    dialogSetting,
    value,
    enabled,
  })

export const updateDateFormat = ({
  id = settings.CURRENT_INSTITUTION_LOOKUP_ID,
  dateFormat,
}: {
  id?: string
  dateFormat?: string
}) =>
  http2({
    method: 'POST',
    url: `/api/v0/institution/${id}/update_date_format/`,
    shape: t.type({ dateFormat: t.string }),
    data: { dateFormat },
  })

export const updateInstitutionData = (
  id: string,
  data: Partial<IInstitution>
) => http.patch<IInstitution>(`/api/v0/institution/${id}/`, data)

interface IAddEmailDomainToInstitutionRequestData {
  readonly id?: string
  readonly emailDomain: string
}
export const addEmailDomainToInstitution = ({
  id = settings.CURRENT_INSTITUTION_LOOKUP_ID,
  emailDomain,
}: IAddEmailDomainToInstitutionRequestData) =>
  http.post<IInstitution>(`/api/v0/institution/${id}/add_email_domain/`, {
    domain: emailDomain,
  })

export const removeEmailDomainFromInstitution = ({
  id = settings.CURRENT_INSTITUTION_LOOKUP_ID,
  emailDomain,
}: IAddEmailDomainToInstitutionRequestData) =>
  http.post<IInstitution>(`/api/v0/institution/${id}/remove_email_domain/`, {
    domain: emailDomain,
  })

export const createBot = (data: ICreateBotRequestData) =>
  http.post<ICreateBotResponseData>('/api/v0/bot/', data)

export const updateContact = (id: string, data: IUpdateProfileRequestData) =>
  http.put<IUpdateProfileResponseData>(
    `/api/v0/triage/contacts/${id}/update_profile/`,
    data
  )
export const createContact = (data: ICreateContactRequestData) =>
  http.post<IContactResponseData>(`/api/v0/triage/contacts/`, data)

export const getSMSSettings = () =>
  http.get<ISMSSettingsResponse>('/api/v0/settings/sms/')

export const updateSMSSettings = (payload: IUpdateSMSSettingsRequestData) =>
  http.patch<ISMSSettingsResponse>('/api/v0/settings/sms/', payload)

const ConversationSettingsShape = t.type({
  liveChatDuration: t.number,
  liveChatDisabled: t.boolean,
  dialogSettings: et.nullable(
    t.partial({
      unknownStudentDialogBotOn: et.nullable(t.string),
      unknownStudentDialogBotOff: et.nullable(t.string),
      knownNonContactedStudentDialog: et.nullable(t.string),
      botOffDialog: et.nullable(t.string),
      createAndIntroDialog: et.nullable(t.string),
      botOffNoDialog: et.nullable(t.boolean),
      finishedConversationDialog: et.nullable(t.string),
      aiDisabled: et.nullable(t.boolean),
      aiResponseMessageDisabled: et.nullable(t.boolean),
      escalationsDisabledDuringLiveChat: et.nullable(t.boolean),
    })
  ),
})

export type ConversationSettingsShapeType = t.TypeOf<
  typeof ConversationSettingsShape
>

export const getConversationSettings = () =>
  http2({
    method: 'GET',
    url: `/api/v0/settings/conversation/`,
    shape: ConversationSettingsShape,
  })

export const updateConversationSettings = (data: {
  readonly liveChatDuration?: number
  readonly liveChatDisabled?: boolean
}) =>
  http2({
    method: 'PATCH',
    url: `/api/v0/settings/conversation/`,
    data,
    shape: ConversationSettingsShape,
  })

export const getWebBotSettings = () =>
  http.get<IWebBotSettingsResponse>('/api/v0/settings/web/')

export const WebchatSettingsType = t.type({
  id: t.number,
  auth_enabled: t.boolean,
  bot_name: t.string,
  name: t.string,
  archived: t.boolean,
  bot_title: t.string,
  college_id: t.string,
  custom_badge_enabled: t.boolean,
  custom_badge: et.nullable(
    t.type({
      id: t.number,
      href: t.string,
    })
  ),
  embed_token: t.string,
  integration_enabled: t.boolean,
  intro_dialog_enabled: t.boolean,
  intro_dialog: et.nullable(
    t.type({
      id: t.string,
      name: t.string,
      prompt: et.nullable(t.string),
    })
  ),
  intro_message: t.string,
  theme_color: t.string,
  text_color: t.string,
  integration_locked: t.boolean,
  popup_enabled: t.boolean,
  popup_time_seconds: t.number,
  popup_message: t.string,
  button_label_enabled: t.boolean,
  button_label_text: t.string,
  quick_actions_enabled: t.boolean,
  quick_actions: t.array(
    t.type({
      id: t.number,
      quick_menu_text: t.string,
      command: t.type({
        id: t.number,
        enabled: t.boolean,
        words: t.array(
          t.type({
            id: t.number,
            command_text: t.string,
          })
        ),
      }),
    })
  ),
  expires_in: t.union([t.number, t.null]),
})

export const getWebChats = () =>
  http2({
    url: '/api/v0/settings/webchats/',
    method: 'GET',
    shape: t.array(WebchatSettingsType),
  })

export const getWebChat = (id: number) =>
  http2({
    url: `/api/v0/settings/webchats/${id}/`,
    method: 'GET',
    shape: WebchatSettingsType,
  })

export const createWebChat = (data: IUpdateWebBotSettingsRequestData) =>
  http2({
    url: '/api/v0/settings/webchats/',
    method: 'POST',
    data,
    shape: WebchatSettingsType,
  })

export const updateWebChat = (
  id: number,
  data: Partial<IUpdateWebBotSettingsRequestData>
) =>
  http2({
    url: `/api/v0/settings/webchats/${id}/`,
    method: 'PATCH',
    data,
    shape: WebchatSettingsType,
  })

export const deleteWebChat = (webChatId: number) =>
  http2({
    url: `/api/v0/settings/webchats/${webChatId}/`,
    method: 'DELETE',
    shape: t.unknown,
  })

export const updateWebBotSettings = (data: IUpdateWebBotSettingsRequestData) =>
  http.patch<IWebBotSettingsResponse>('/api/v0/settings/web/', data)

export const updateWebBotLock = (data: IUpdateWebBotLockRequestData) =>
  http.post('/api/v0/settings/web/update_lock/', data)

export const getUnderstandingV3 = (id: string) =>
  http.get<{
    readonly id: string
    readonly topic: string
    readonly sampleQuestion: string
    readonly questionGroups: ReadonlyArray<{
      readonly id: string
      readonly questions: ReadonlyArray<{
        readonly id: number
        readonly text: string
        readonly institutionQuestion?: boolean
      }>
      readonly created: string
    }>
    readonly globalAnswerGroup: {
      readonly answers: ReadonlyArray<{
        readonly id: number
        readonly text: string
      }>
      readonly created: string
    } | null
    readonly institutionAnswerGroup: {
      readonly answers: ReadonlyArray<{
        readonly id: number
        readonly text: string
      }>
      readonly created: string
    } | null
    readonly visibleForInstitution?: boolean
    readonly matchingForInstitution?: boolean
  }>(`/api/v0/understandings/get_by_id_v3/${id}/`)

export const updateQuestionGroupV3 = ({
  understandingId,
  questions,
  questionGroupId,
}: {
  readonly questions: ReadonlyArray<{
    readonly id: number | null
    readonly text: string
  }>
  readonly understandingId: string
  readonly questionGroupId: string
}) =>
  http.patch<{
    readonly id: string
    readonly questions: ReadonlyArray<{
      readonly id: number
      readonly text: string
    }>
    readonly created: string
  }>(
    `/api/v0/understandings/${understandingId}/question_groups/${questionGroupId}/update_v3`,
    { questions }
  )

export const updateAnswerGroupV3 = ({
  understandingId,
  answerGroup,
}: {
  readonly answerGroup: {
    readonly isGlobal: boolean
    readonly answers: ReadonlyArray<{
      readonly id: number | null
      readonly text: string
    }>
  }
  readonly understandingId: string
}) =>
  http.patch<{
    readonly isGlobal: boolean
    readonly answers: ReadonlyArray<{
      readonly id: number
      readonly text: string
    }>
    readonly created: string
  }>(
    `/api/v0/understandings/${understandingId}/answer_groups/update_v3`,
    answerGroup
  )

interface IDeleteQuestionGroupFromUnderstanding {
  readonly understandingId: string
  readonly questionGroupId: string
}
export const deleteQuestionGroupFromUnderstanding = ({
  understandingId,
  questionGroupId,
}: IDeleteQuestionGroupFromUnderstanding) =>
  http.delete(
    `/api/v0/understandings/${understandingId}/question_groups/${questionGroupId}/`
  )

export const getUsersByLocation = ({ query }: IViewerListRequestData) => {
  return http.get<Array<IGetUsersByLocationResponseData>>(
    `/api/v0/triage/users/searchv2/?query=${encodeURIComponent(query)}`
  )
}

export const updateUserLocation = (data: IUpdateUserLocationRequestData) =>
  http.post<IUpdateUserLocationResponseData>(
    `/api/v0/triage/users/update_user_location/`,
    data
  )

export const searchContact = (query: string) =>
  http.get<IContactResponseData[]>(
    `/api/v0/triage/contacts/search/?query=${encodeURIComponent(query)}`
  )

export const getCurrentUserProfile = () =>
  http.get<IUserResponseData>('/api/v0/users/current/')

export const searchUnderstandings = (
  query: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse<ISearchUnderstandingByTopicResponseData>> =>
  // NOTE (tmoliter 10/31/24)
  // This is very nearly the same as `searchQuestionsV2`, but some amount of combining
  // response typing is necessary to actually merge them.
  http.post(
    '/api/v0/triage/understanding/search/',
    { question: query },
    { cancelToken }
  )

export const sendMessage = (message: ISendSingleTextRequestData) =>
  http.post(`/api/v0/triage/message/`, message)

export const updateCurrentInstitution = (institution: string) =>
  http.put<ILimitedInstitution>(
    `/api/v0/triage/institution/update_institution/`,
    {
      currentInstitution: institution,
    }
  )

export const getUnderstandingTopics = () =>
  http.get<string[]>(`/api/v0/triage/understanding/all_topics/`)

export const fetchScheduledCampaigns = (start: string, end: string) =>
  http.get<IScheduledCampaign[]>(`/api/v0/scheduled-messages/`, {
    params: { start, end },
  })

interface IDeleteScheduledMessageMetadata {
  new_end_date: string
}
export const deleteScheduledCampaign = (
  id: string,
  type?: DeleteRecurringCampaignTypeEnum,
  date?: string
) =>
  http.delete<IDeleteScheduledMessageMetadata>(
    `/api/v0/scheduled-messages/${id}/`,
    {
      params: { type, occurrence_date: date },
    }
  )

interface IGetCampaigns {
  readonly query?: string
  readonly limit?: number
  readonly page?: number
  readonly sort: {
    readonly name: SortBy
    readonly order: SortOrder
  }
  readonly sidebarFilter?: string
  readonly cancelToken?: CancelToken
  readonly lessData?: boolean
  readonly dateFilter?: {
    readonly start?: string
    readonly end?: string
  }
}

export const getCampaignTriggers = ({
  query,
  sort,
}: Pick<IGetCampaigns, 'query' | 'sort'>) =>
  http2({
    method: 'GET',
    url: '/api/v0/data-triggered-campaigns/',
    params: {
      query,
      sort: sort.name,
      order: sort.order,
    },
    shape: t.array(
      t.type({
        id: t.string,
        name: t.string,
        description: t.string,
        isRespondable: t.boolean,
        countEligibleUsersProcessed: t.number,
        distinctResponses: t.number,
        enabled: t.boolean,
        lastSentDate: et.nullable(t.string),
        dialogId: t.string,
        contactFilterId: t.number,
        timeDelay: t.number,
        sendCampaignOnce: t.boolean,
      })
    ),
  })

export const createCampaignTrigger = (
  formData: ICampaignSchedulerRequestData
) => {
  return http2({
    method: 'POST',
    url: `/api/v0/data-triggered-campaigns/`,
    data: formData,
    shape: t.type({
      id: t.string,
      name: t.string,
      description: t.string,
      enabled: t.boolean,
      dialogId: t.string,
      contactFilterId: t.number,
      timeDelay: t.number,
      sendCampaignOnce: t.boolean,
    }),
  })
}

export const updateCampaignTrigger = ({
  id,
  data,
}: {
  id: string
  data: Partial<ICampaignSchedulerRequestData>
}) => {
  return http2({
    method: 'PATCH',
    url: `/api/v0/data-triggered-campaigns/${id}/`,
    data,
    shape: t.type({
      name: t.string,
      description: t.string,
      dialogId: t.string,
      contactFilterId: t.number,
      enabled: t.boolean,
      timeDelay: t.number,
      sendCampaignOnce: t.boolean,
      region: t.union([t.string, t.null]),
    }),
  })
}

const dialogStatesType = t.array(
  t.type({
    id: t.string,
    parentDialog: t.string,
    showPauseTimeForm: t.union([t.boolean, t.undefined]),
    prompt: t.string,
    personalizedPrompt: t.union([t.string, t.null, t.undefined]),
    promptType: t.union([
      t.literal(PromptType.number),
      t.literal(PromptType.boolean),
      t.literal(PromptType.open),
      t.literal(PromptType.auto),
    ]),
    pauseTime: t.union([t.number, t.null, t.undefined]),
    enterActions: t.union([
      t.array(
        t.type({
          newlyCreated: t.union([t.undefined, t.boolean]),
          reprompt: t.union([t.undefined, t.boolean]),
          args: t.UnknownRecord,
          name: t.literal(ExitActionType.Ragtime),
        })
      ),
      t.null,
      t.undefined,
    ]),
    exitActions: t.union([
      t.array(
        t.type({
          newlyCreated: t.union([t.undefined, t.boolean]),
          reprompt: t.union([t.undefined, t.boolean]),
          args: t.UnknownRecord,
          name: t.union([
            t.literal(ExitActionType.Save),
            t.literal(ExitActionType.ScheduleJob),
            t.literal(ExitActionType.SendEmail),
            t.literal(ExitActionType.updateOrCreateHubspotContact),
            t.literal(ExitActionType.sendToWebhook),
            t.literal(ExitActionType.SetMessagingStatus),
          ]),
        })
      ),
      t.null,
      t.undefined,
    ]),
    nextStates: t.record(
      t.string,
      t.union([
        t.type({
          default: t.union([t.string, t.boolean]),
        }),
        t.undefined,
      ])
    ),
    media: t.union([t.string, t.null, t.undefined]),
    mediaName: t.union([t.string, t.undefined]),
    multipleChoices: t.union([
      t.array(t.record(t.string, t.string)),
      t.null,
      t.undefined,
    ]),
    openPromptLabel: t.union([t.string, t.null, t.undefined]),
    totalResponseCount: t.union([t.number, t.undefined]),
    choiceResponseCounts: t.union([
      t.array(
        t.type({
          label: t.string,
          value: t.number,
          cleaned: t.union([t.number, t.string]),
          promptType: t.union([t.literal('Number'), t.literal('Boolean')]),
        })
      ),
      t.null,
    ]),
    dialogStateSentToCount: t.union([t.number, t.undefined]),
  })
)

const dialogType = t.type({
  description: t.string,
  topic: t.union([t.string, t.null]),
  audience: t.union([t.string, t.null]),
  timing: t.union([t.string, t.null]),
  lifeCycle: t.union([t.string, t.null]),
  principle: t.union([t.string, t.null]),
  realImpact: t.union([t.string, t.null]),
  humanName: t.string,
  id: t.string,
  initialState: t.string,
  draft: t.boolean,
  name: t.string,
  messagingService: t.string,
  reminders: t.union([
    t.array(
      t.type({
        prompt: t.string,
        after: t.number,
      })
    ),
    t.undefined,
  ]),
  expirationLength: t.union([t.number, t.undefined]),
  createdAt: t.string,
  createdBy: t.union([t.string, t.undefined]),
  isMainstayStaff: t.union([t.boolean, t.undefined]),
  updatedAt: t.string,
  scriptHistory: t.union([
    t.array(
      t.type({
        isMainstayStaff: t.union([t.boolean, t.undefined]),
        createdAt: t.union([t.string, t.undefined]),
        createdBy: t.union([t.string, t.undefined]),
        updatedAt: t.union([t.string, t.undefined]),
        updatedBy: t.union([t.string, t.undefined]),
      })
    ),
    t.undefined,
  ]),
  custom: t.boolean,
  customLogic: t.union([
    t.array(
      t.type({
        type: t.union([t.literal('SKIP'), t.literal('CUSTOM_NEXT_STATE')]),
        id: t.string,
      })
    ),
    t.null,
  ]),
  sentToUsers: t.union([t.boolean, t.undefined]),
  published: t.union([t.boolean, t.undefined]),
  labels: t.union([
    t.array(
      t.type({
        id: t.number,
        name: t.string,
        count: t.number,
      })
    ),
    t.undefined,
  ]),
  collections: t.union([
    t.array(
      t.type({
        id: t.number,
        name: t.string,
        description: t.string,
        count: t.number,
        shared_institutions: t.array(
          t.type({
            id: t.string,
            displayName: t.string,
          })
        ),
      })
    ),
    t.undefined,
  ]),
})

export const getCampaignTrigger = ({
  id,
  audience,
  editing = false,
}: {
  id: string
  audience?: string
  editing?: boolean
}) => {
  return http2({
    method: 'GET',
    url: `/api/v0/data-triggered-campaigns/${id}/`,
    params: {
      audience,
      editing,
    },
    shape: t.type({
      trigger: t.type({
        id: t.string,
        name: t.string,
        description: t.string,
        dialogId: t.string,
        isInteractive: t.boolean,
        contactFilterId: t.number,
        timeDelay: t.number,
        sendCampaignOnce: t.boolean,
        enabled: t.boolean,
        region: t.union([t.string, t.null]),
        engagementData: t.type({
          passiveUsersCount: t.number,
          activeUsersCount: t.number,
          priorSoftStopUsersCount: t.number,
          priorHardStopUsersCount: t.number,
          priorInvalidPhoneUsersCount: t.number,
          resultingSoftStopUsersCount: t.number,
          resultingHardStopUsersCount: t.number,
          messagedUsersCount: t.number,
          optOutUsersCount: t.number,
          deliveryFailureUsersCount: t.number,
          totalIntendedRecipients: t.number,
          totalEligibleRecipients: t.number,
          countEligibleUsersProcessed: t.number,
          totalRecipients: t.number,
          totalDistinctResponses: t.number,
          triggerId: t.string,
        }),
        createdAt: t.string,
        filterName: t.string,
        creationInfo: t.type({
          firstName: t.string,
          lastName: t.string,
          createdAt: t.string,
        }),
        started: t.boolean,
        dialog: dialogType,
      }),
      workflow_steps: dialogStatesType,
    }),
  })
}

export const deleteCampaignTrigger = (id: string) => {
  return http2({
    method: 'DELETE',
    url: `/api/v0/data-triggered-campaigns/${id}`,
    shape: t.unknown,
  })
}

export const getDTCQueue = ({
  id,
  filterData,
  search,
  page,
  sortDir = 'asc',
  cancelToken,
}: {
  id: string
  filterData: IFilterModalState
  search?: string
  page?: number
  sortDir?: 'asc' | 'desc'
  cancelToken: CancelToken
}) => {
  const baseUrl = `/api/v0/data-triggered-campaigns/${id}/get_queue/`

  return http2({
    method: 'GET',
    url: `${baseUrl}`,
    params: { ...filterData, search, sortDir, page },
    cancelToken,
    shape: t.array(
      t.type({
        _id: t.string,
        contact_id: t.string,
        crm_id: et.nullable(t.string),
        phone: et.nullable(t.string),
        email: et.nullable(t.string),
        name_first: et.nullable(t.string),
        name_last: et.nullable(t.string),
        entered_audience: t.string,
        total_count: t.number,
      })
    ),
  })
}

export const getCampaigns = (
  {
    sidebarFilter,
    query,
    limit,
    page,
    cancelToken,
    sort,
    lessData,
    dateFilter,
  }: IGetCampaigns = {
    sort: { name: SortBy.date, order: SortOrder.desc },
  }
) =>
  http.get<{
    readonly campaigns: ReadonlyArray<ICampaignHistoryResponseData>
    readonly counts: Readonly<ICountsResponseData>
  }>('/api/v0/campaigns/', {
    cancelToken,
    params: {
      query,
      limit,
      page,
      sort: sort.name,
      order: sort.order,
      sidebar_filter: sidebarFilter,
      less_data: lessData,
      version: 'v2',
      start: dateFilter?.start,
      end: dateFilter?.end,
    },
  })

const CampaignCountsShape = t.type({
  other_intro_count: t.number,
  webchat_intro_count: t.number,
  datatriggered_count: t.union([t.number, t.undefined]),
  hellopages_count: t.number,
  recurring_count: t.union([t.number, t.undefined]),
  sent_count: t.number,
  total_count: t.number,
  upcoming_count: t.number,
  sending_count: t.number,
})
export type ICountsResponseData = t.TypeOf<typeof CampaignCountsShape>

export const getCampaignCountsByType = (
  query: string,
  cancelToken: CancelToken,
  startDate?: string,
  endDate?: string
) =>
  http2({
    url: '/api/v0/campaigns/get_campaigns_counts_by_type/',
    method: 'GET',
    params: {
      query,
      start: startDate,
      end: endDate,
    },
    cancelToken,
    shape: t.type({
      counts: CampaignCountsShape,
    }),
  })

export const getCampaignById = (id: ICampaign['id'], audience?: string) => {
  return http.get<ICampaignDetailsResponseData>(
    `/api/v0/campaigns/${id}/${audience ? `?audience=${audience}` : ''}`
  )
}

export const getRecurringCampaignById = (
  id: ICampaign['id'],
  includeAggregate: boolean = false
) =>
  http.get<IRecurringCampaignDetailsResponseData>(
    `/api/v0/recurring-campaigns/${id}/`,
    { params: { includeAggregate } }
  )

export const COUNT_RECURRING_CAMPAIGNS_PER_PAGE = 10

export const getAggListRecurringCampaigns = (
  id: ICampaign['id'],
  page: number,
  pageSize: number = COUNT_RECURRING_CAMPAIGNS_PER_PAGE,
  sortBy: string,
  order: 'asc' | 'desc'
): Promise<Either<Http2ErrorUnion, IRecurringCampaignsListResponseData>> => {
  const offset = (page - 1) * pageSize
  return http2({
    method: 'GET',
    url: `/api/v0/campaigns/${id}/list_agg_recurring_campaigns/?offset=${offset}&limit=${pageSize}&order=${order}&sortBy=${sortBy}`,
    shape: t.type({
      next: et.nullable(t.string),
      previous: et.nullable(t.string),
      count: t.number,
      results: t.array(
        t.type({
          id: t.string,
          sent_date: t.string,
          count_active_recipients: t.number,
          count_passive_recipients: t.number,
          count_opt_outs: t.number,
          count_pauses: t.number,
          count_delivery_failures: t.number,
        })
      ),
    }),
  })
}

export const getCampaignStatusById = (
  taskId: string,
  isDataTriggered?: boolean
) => {
  const campaignType = getCampaignRequestPath({
    isRecurring: false,
    isDataTriggered,
  })
  return http.get<ICampaignReportPollResponse>(
    `/api/v0/${campaignType}/${taskId}/poll/`
  )
}

export const generateCampaignReport = ({
  campaignId,
  preferences,
  reportFileName,
  workflowResponses,
  isRecurring = false,
  isDataTriggered = false,
  isHelloPage = false,
  isWebBot = false,
  isAggregate,
  dialogId,
  campaignReportType,
  audience,
}: Omit<IGenerateCampaignReportPayload, 'onDownloadReportComplete'>) => {
  const campaignType = getCampaignRequestPath({
    isRecurring,
    isDataTriggered,
    isHelloPage,
    isWebBot,
  })
  return http.post<IGenerateCampaignResponse>(
    `/api/v0/${campaignType}/${campaignId}/generate_report/`,
    {
      preferences: { reportFileName, ...preferences },
      ...(preferences?.startDate && { startDate: preferences.startDate }),
      ...(preferences?.endDate && { endDate: preferences.endDate }),
      workflowResponses,
      isAggregate,
      isHelloPage,
      isWebBot,
      dialogId,
      campaignReportType,
      audience,
    }
  )
}

export const getCampaignImportDetailsById = (id: ICampaign['id']) => {
  return http2({
    method: 'GET',
    url: `/api/v0/campaigns/${id}/campaign_import/`,
    shape: t.type({
      import_report_id: t.string,
      import_name: t.string,
      import_date: t.string,
      import_user: t.string,
      import_label: t.string,
      contact_labels: t.array(t.string),
      count_contacts: t.number,
    }),
  })
}

export const getDeliveryFailuresDetail = ({
  id,
  isRecurring,
  isAggregate,
  isHelloPage,
  isWebBot,
  dialogId,
  audienceFilter,
  isDataTriggered,
}: {
  id: ICampaign['id']
  isRecurring?: boolean
  isAggregate?: boolean
  isHelloPage?: boolean
  isWebBot?: boolean
  audienceFilter?: string
  dialogId?: string
  isDataTriggered?: boolean
}) => {
  const campaignType = getCampaignRequestPath({
    isRecurring,
    isDataTriggered,
    isHelloPage,
    isWebBot,
  })
  return http2({
    method: 'GET',
    url: `/api/v0/${campaignType}/${id}/delivery_failures_detail/`,
    params: {
      isAggregate,
      audience: audienceFilter,
      isHelloPage,
      isWebBot,
      dialogId,
    },
    shape: t.type({
      name: t.string,
      delivery_failures_messages: t.record(
        t.string,
        t.type({
          count: t.number,
          description: t.string,
        })
      ),
      total_delivery_failures_users_count: t.number,
      delivery_failures_messages_count: t.number,
      prior_opt_outs_count: t.number,
      prior_paused_count: t.number,
      failed_users: t.number,
      prior_invalid_phone_users_count: t.number,
    }),
  })
}

export const shortenLink = (link: string) =>
  http2({
    method: 'POST',
    url: '/api/v1/util/shorten-link/',
    shape: t.type({
      shortLink: t.string,
    }),
    data: {
      link,
    },
  })

export const fetchKnowledgeSeeds = () => {
  return http.get<IFetchKnowledgeSeedsResponseData>(`/api/v0/knowledge_base/`)
}

export const removeKnowledgeSeed = (id: string) => {
  return http.put(`/api/v0/knowledge_base/${id}/remove_seed/`)
}

export const stopCurrentDialogForStudent = (studentId: string) =>
  http.put<IUpdateProfileResponseData>(
    `/api/v0/triage/contacts/${studentId}/stop_dialog/`
  )

export const listImportLabels = ({
  daysDelta,
  search,
  region,
}: {
  daysDelta: number
  search?: string
  region?: string | null
}) => {
  return http.get<IImportLabelResponse[]>(
    `/api/v0/scheduled-messages/labels/?delta=${daysDelta} 00:00:00` +
      (search ? `&search=${search}` : '') +
      (region ? `&region=${region}` : '')
  )
}

export const createScheduledMessage = (
  formData: ICampaignSchedulerRequestData
) => {
  return http.post<{ multiAudienceFailures: string[] }>(
    `/api/v0/scheduled-messages/`,
    formData
  )
}

export const updateScheduledMessage = (
  formData: ICampaignSchedulerRequestData,
  id: string
) => {
  return http.put<IScheduledMessageDetail>(
    `/api/v0/scheduled-messages/${id}/`,
    formData
  )
}

const recurrenceSettingsShape = t.type({
  interval: t.union([t.null, t.string]),
  cronInterval: t.union([t.null, t.string]),
  startDate: t.union([t.null, t.string]),
  endDate: t.union([t.null, t.string]),
  infinite: t.union([t.null, t.boolean]),
  sendCampaignOnce: t.union([t.null, t.boolean]),
  changedOccurrences: t.array(t.string),
})

const scheduledMessageShape = t.type({
  createdAt: t.string,
  description: t.string,
  importReportId: t.union([t.null, t.string]),
  messagingService: t.string,
  on: t.string,
  campaign: t.string,
  segment: t.union([t.null, t.string]),
  savedQuery: t.union([t.null, t.string]),
  recipientLabel: t.union([t.null, t.string]),
  started: t.union([t.null, t.string]),
  region: et.nullable(t.string),
  workflowHumanName: t.string,
  query: t.string,
  scheduledAt: t.string,
  test: t.boolean,
  importLabels: t.array(t.string),
  contactLabels: t.array(t.string),
  users: t.array(t.string),
  dialogId: t.string,
  contactFilter: t.number,
  recurring: t.boolean,
  recurrenceSettings: recurrenceSettingsShape,
})

export const updateScheduledMessageDate = (id: string, date: string) => {
  return http2({
    url: `/api/v0/scheduled-messages/${id}/update_scheduled_message_date/`,
    method: 'PATCH',
    data: { date },
    shape: scheduledMessageShape,
  })
}

export const updateRecurringCampaignEndDate = (
  id: string,
  endDate?: string,
  isInfinite?: boolean
) =>
  http2({
    url: `/api/v0/scheduled-messages/${id}/update_recurring_campaign_end_date/`,
    method: 'PATCH',
    data: { end_date: endDate, is_infinite: isInfinite },
    shape: t.type({
      interval: fromEnum('RepeatInterval', RepeatInterval),
      cronInterval: t.string,
      startDate: t.string,
      endDate: t.string,
      infinite: t.boolean,
      sendCampaignOnce: t.boolean,
      changedOccurrences: t.array(
        t.type({
          date: t.string,
          type: fromEnum(
            'ChangedOccurrenceTypeEnum',
            ChangedOccurrenceTypeEnum
          ),
        })
      ),
    }),
  })

export interface IScheduledMessageDetail {
  readonly id: string
  readonly isIntro?: boolean
  readonly messagingService: string
  readonly scheduledAt: string
  readonly users: number
  readonly optOutUsers: number
  readonly deliveryFailureUsers: number
  readonly distinctResponses: number
  readonly expiresAt: string | undefined
  readonly name: string
  readonly description: string
  readonly recurring: boolean
  readonly usersContacted?: number
  readonly dialogId: string
  readonly isRespondable: boolean
  readonly importReportId: null | string
  readonly recipientLabel: null | string
  readonly contactFilter?: number
  readonly region: string | null
  readonly filterName?: string
  readonly contactLabels: {
    id: string
    text: string
    institutionId: string
    createdAt: string
    createdBy: string
  }[]
  readonly importLabels: string[]
  readonly importAutoLabel: string | null
  readonly dialog: ICampaignScriptResponse
  readonly states: ICampaignScriptStep[]
  readonly recurrenceSettings: IRecurrenceSettings
}

export interface ITrigger
  extends Pick<
    IScheduledMessageDetail,
    'name' | 'description' | 'dialogId' | 'filterName'
  > {
  readonly timeDelay: number
  readonly contactFilterId: number
  readonly dialog?: ICampaignScriptResponse
  readonly sendCampaignOnce: boolean
  readonly region: string | null
  readonly enabled?: boolean
}

export interface IDataTriggeredCampaignDetail {
  trigger: ITrigger
  workflow_steps: ICampaignScriptStep[]
}

export const fetchScheduledMessage = (id: string) => {
  return http.get<IScheduledMessageDetail>(`/api/v0/scheduled-messages/${id}/`)
}

export const pollStudentGroupUpload = (taskId: string) => {
  return http.get<IUploadStudentGroupPollingResponseData>(
    `/api/v0/csv-ingestion/status/?task_id=${taskId}`
  )
}

export const listImportReports = (
  pageNumber: number,
  pageSize: number = IMPORT_REPORTS_PER_PAGE,
  ordering: IImportReportListOrdering = '-createdAt',
  searchQuery: string = ''
) => {
  const offset = (pageNumber - 1) * pageSize
  return http.get<IPaginatedResponse<Omit<IImportReport, 'errors'>[]>>(
    `/api/v0/import-reports/?offset=${offset}&limit=${pageSize}&ordering=${ordering}&search=${searchQuery}`
  )
}

export const getImportReport = (id: string) => {
  return http.get<IImportReport>(`/api/v0/import-reports/${id}/`)
}

export const getRecipients = (
  importLabels: string[] = [],
  contactFilterId: number | undefined = undefined,
  contactFilterObj: IContactFilterRequestData | undefined = undefined,
  page: number,
  pageSize: number = IMPORTED_RECIPIENTS_PER_PAGE,
  ordering: string = '-createdAt',
  searchQuery: string = '',
  taskId: string | undefined = undefined
) => {
  const offset = (page - 1) * pageSize
  return http.post<IFilteredContactsResponseData>(
    `api/v0/contacts/recipients/?offset=${offset}&limit=${pageSize}&ordering=${ordering}&search=${searchQuery}`,
    { importLabels, contactFilterId, contactFilterObj, taskId }
  )
}

export const deleteFilterTask = (taskId: string) =>
  http2({
    url: `api/v0/contacts/clear_filters_cache/?taskId=${taskId}`,
    method: 'DELETE',
    shape: t.unknown,
  })

export const fetchUsersForOrg = () => {
  return http.get<IUserResponseData[]>('/api/v0/users/')
}

export const updateActivityLog = (
  id: string,
  request: Partial<IUpdateActivityLogRequest>
) => {
  return http.patch<IActivityLogResponseData>(
    `/api/v0/activity/${id}/`,
    request
  )
}

export const deleteUnderstandingV2 = (id: string) => {
  return http.delete(`/api/v0/understandings/${id}/`)
}

export const listCampaignScripts = (
  query: string = '',
  page = 1,
  sortField: keyof ICampaignScriptPreview = 'createdAt',
  sortOrder: 'asc' | 'desc' = 'desc',
  label?: string
) => {
  const limit = settings.CAMPAIGN_SCRIPT_LIST_LIMIT
  const offset = (page - 1) * limit
  const ordering = (sortOrder === 'desc' ? '-' : '') + sortField
  return http.get<ICampaignScriptPreviewListResponse>(`/api/v0/dialogs/`, {
    params: { query, limit, offset, ordering, label },
  })
}

export const getCampaignScripts = ({
  query = '',
  page = 1,
  sortField,
  sortOrder,
  label,
}: {
  query: string
  page: number
  sortField: string | undefined
  sortOrder: 'asc' | 'desc' | undefined
  label: string
}) => {
  const limit = settings.CAMPAIGN_SCRIPT_LIST_LIMIT
  const offset = (page - 1) * limit
  const ordering = (sortOrder === 'desc' ? '-' : '') + sortField
  return http2({
    url: `/api/v0/dialogs/`,
    method: 'GET',
    params: { query, limit, offset, ordering, label },
    shape: t.type({
      count: t.number,
      next: et.nullable(t.string),
      previous: et.nullable(t.string),
      results: t.array(
        t.type({
          id: t.string,
          name: t.string,
          description: t.string,
          category: t.union([t.string, t.undefined, t.null]),
          topic: t.union([t.string, t.undefined, t.null]),
          lifeCycle: t.union([t.string, t.undefined, t.null]),
          audience: t.union([t.string, t.undefined, t.null]),
          timing: t.union([t.string, t.undefined, t.null]),
          realImpact: t.union([t.string, t.undefined, t.null]),
          firstMessage: t.string,
          createdAt: t.string,
          custom: t.boolean,
          published: t.boolean,
          sentToUsers: t.boolean,
          isInteractive: t.boolean,
          collections: t.array(t.unknown),
          labels: t.array(
            t.type({
              id: t.number,
              name: t.string,
              count: t.number,
              institution_id: t.string,
              deleted_at: et.nullable(t.string),
            })
          ),
          frequencyCount: t.union([t.number, t.undefined, t.null]),
        })
      ),
    }),
  })
}

export const listTemplateScripts = (
  query: string = '',
  page = 1,
  sortField: keyof ICampaignScriptPreview = 'createdAt',
  sortOrder: 'asc' | 'desc' = 'desc',
  status?: 'published' | 'unpublished',
  collection?: number,
  tags?: string[],
  topic?: string
) => {
  const limit = settings.CAMPAIGN_SCRIPT_LIST_LIMIT
  const offset = (page - 1) * limit
  const ordering = (sortOrder === 'desc' ? '-' : '') + sortField
  return http.get<ITemplateListResponse>(`/api/v0/templates/`, {
    params: {
      query,
      limit,
      offset,
      ordering,
      status,
      collection,
      tags,
      topic,
    },
  })
}

export const getTemplateScripts = ({
  query = '',
  page = 1,
  sortField,
  sortOrder,
  status,
  collection,
  tags,
  topic,
}: {
  query: string
  page: number
  sortField?: string
  sortOrder?: 'asc' | 'desc'
  status?: 'published' | 'unpublished'
  collection?: number
  tags?: string | string[]
  topic?: string
}) => {
  const limit = settings.CAMPAIGN_SCRIPT_LIST_LIMIT
  const offset = (page - 1) * limit
  const ordering = (sortOrder === 'desc' ? '-' : '') + sortField
  return http2({
    url: `/api/v0/templates/`,
    method: 'GET',
    params: { query, limit, offset, ordering, status, collection, tags, topic },
    shape: t.type({
      count: t.number,
      next: et.nullable(t.string),
      previous: et.nullable(t.string),
      results: t.array(
        t.type({
          id: t.string,
          name: t.string,
          description: t.string,
          category: t.union([t.string, t.undefined, t.null]),
          topic: t.union([t.string, t.undefined, t.null]),
          lifeCycle: t.union([t.string, t.undefined, t.null]),
          audience: t.union([t.string, t.undefined, t.null]),
          timing: t.union([t.string, t.undefined, t.null]),
          realImpact: t.union([t.string, t.undefined, t.null]),
          firstMessage: t.string,
          createdAt: t.string,
          custom: t.boolean,
          published: t.boolean,
          sentToUsers: t.boolean,
          isInteractive: t.boolean,
          collections: t.array(t.unknown),
          labels: t.array(
            t.type({
              id: t.number,
              name: t.string,
              count: t.number,
              institution_id: t.string,
              deleted_at: et.nullable(t.string),
            })
          ),
          frequencyCount: t.union([t.number, t.undefined, t.null]),
        })
      ),
    }),
  })
}

export const getAllCampaignScriptPreviews = () => {
  return http.get<ICampaignScriptPreviewResponse[]>(`/api/v0/dialogs/all/`)
}

export const retrieveCampaignScripts = (
  id: string,
  isTemplate: boolean = false
) => {
  return http.get<ICampaignScriptRetrieveResponse>(`/api/v0/dialogs/${id}/`, {
    params: { is_template: isTemplate },
  })
}

export const campaignScriptDuplicate = (
  id: string,
  humanName: string,
  labelsToAdd: number[],
  labelsToCreate: string[]
) => {
  return http.post<ICampaignScriptRetrieveResponse>(
    `/api/v0/dialogs/${id}/duplicate/`,
    { humanName, labelsToAdd, labelsToCreate }
  )
}

export const copyToScriptLibrary = (id: string, humanName?: string) => {
  return http.post<ICampaignScriptResponse>(`/api/v0/dialogs/${id}/copy_to/`, {
    humanName,
    toTemplateLibrary: true,
  })
}

export const copyFromScriptLibrary = (
  id: string,
  humanName?: string,
  labelsToAdd?: number[],
  labelsToCreate?: string[]
) => {
  return http.post<ICampaignScriptResponse>(
    `/api/v0/templates/${id}/copy_from/`,
    { humanName, fromTemplateLibrary: true, labelsToAdd, labelsToCreate }
  )
}

export const verifyCopyScriptBetweenBots = (
  dialogId: string,
  toInstitution: string,
  fromInstitution?: string
) => {
  return http2({
    method: 'POST',
    url: `api/v0/dialogs/${dialogId}/verify_copy_between/`,
    data: { toInstitution, fromInstitution },
    shape: t.type({
      institution: t.array(
        t.type({
          // TODO(neckenth) - remove redundant `name` field
          name: t.string,
          fromName: t.string,
          fromId: t.number,
          toId: et.nullable(t.number),
          toName: et.nullable(t.string),
          dataType: fromEnum('ContactAttributeType', ContactAttributeType),
        })
      ),
      contact: t.array(
        t.type({
          name: t.string,
          fromName: t.string,
          fromId: t.number,
          toId: et.nullable(t.number),
          toName: et.nullable(t.string),
          dataType: fromEnum('ContactAttributeType', ContactAttributeType),
        })
      ),
    }),
  })
}

export const copyScriptBetweenBots = (
  dialogId: string,
  toInstitution: string
) => {
  return http2({
    method: 'POST',
    url: `api/v0/dialogs/${dialogId}/copy_between/`,
    data: { toInstitution },
    shape: t.type({
      newDialogId: t.string,
    }),
  })
}

export const campaignScriptCreate = (isTemplate: boolean = false) => {
  const url = isTemplate ? `/api/v0/templates/` : `/api/v0/dialogs/`
  return http.post<ICampaignScriptRetrieveResponse>(url)
}

export const campaignScriptDestroy = (
  id: string,
  isTemplate: boolean = false
) => {
  const url = isTemplate ? `/api/v0/templates/${id}/` : `/api/v0/dialogs/${id}/`
  return http.delete(url)
}

function getScriptExpiration(
  expiration: number | null | undefined
): number | null | undefined {
  if (typeof expiration !== 'number') {
    return expiration
  }
  return Math.min(expiration, Number.MAX_SAFE_INTEGER)
}

export const campaignScriptUpdate = (
  request: ICampaignScriptUpdateRequest,
  isTemplate: boolean = false
) => {
  const { id, data } = request
  const payload: ICampaignScriptUpdateRequest['data'] = {
    ...data,
    // limit the number to a safe size we can serialize. If someone types in
    // 9999.. days we'll get an exponential that will fail to parse in the API.
    expirationLength: getScriptExpiration(data.expirationLength),
  }
  const url = isTemplate ? `/api/v0/templates/${id}/` : `/api/v0/dialogs/${id}/`
  return http.patch<ICampaignScriptResponse>(url, payload)
}

export const createScriptInitialState = (
  dialogId: string,
  promptType: PromptType
) => {
  return http.post<ICampaignScriptRetrieveResponse>(
    `/api/v0/dialogs/${dialogId}/states/create_initial/`,
    { promptType }
  )
}

export const campaignScriptStateUpdate = (
  request: ICampaignScriptStateUpdateRequest
) => {
  return http.patch<ICampaignScriptStep>(
    `/api/v0/dialogs/${request.dialogId}/states/${request.dialogStateId}/`,
    request.data
  )
}

export const campaignScriptStateSplice = (
  request: ICampaignScriptStateSpliceRequest
) => {
  return http.patch<{ newDialogState: ICampaignScriptStep }>(
    `/api/v0/dialogs/${request.dialogId}/states/${request.dialogStateId}/splice/`,
    request.data
  )
}

export const campaignScriptStateDeleteEdge = (
  request: ICampaignScriptStateDeleteEdgeRequest
) => {
  return http.delete(
    `/api/v0/dialogs/${request.dialogId}/states/${request.dialogStateId}/delete_edge/?transition_type=${request.transitionType}&is_link=${request.isLink}`
  )
}

const transportSchema = withFallback(
  t.union([t.keyof(TransportEnum), t.literal('unknown')]),
  'unknown'
)

export const fetchTestContacts = () => {
  return http2({
    method: 'GET',
    url: '/api/v0/contacts/list_test_contacts/',
    shape: t.array(
      t.type({
        id: t.string,
        name: t.type({
          first: et.nullable(t.string),
          last: et.nullable(t.string),
          full: et.nullable(t.string),
        }),
        phone: et.nullable(t.string),
        email: withFallback(et.nullable(t.string), null),
        transport: transportSchema,
      })
    ),
  })
}

export const getUsers = () => {
  return http.get<IUser[]>('/api/v0/users/')
}

export const addUser = (data: INewUserRequestData) => {
  return http.post<{ user: IUser; invite_sent: boolean }>(
    '/api/v0/auth/invite/',
    data
  )
}

export const removeUser = ({
  userId,
  all,
}: {
  readonly userId: string
  readonly all?: boolean
}) => {
  return http.patch('/api/v0/users/deactivate/', { userId, removeAll: all })
}

export const resendUserInvite = (id: string) => {
  return http.patch(`/api/v0/users/${id}/resend_invite/`)
}

export const pulse = () => {
  return http.post('/api/v0/auth/pulse/')
}

export const getUserFromEnrollmentToken = (token: string) => {
  return http.get<IEnrollmentInfoResponseData>(
    `/api/v0/auth/enrollment_info/${token}`
  )
}

export const getPasswordConstraintInfoByUserId = (id: string) => {
  return http.get<IUserPasswordRequirementInfo>(
    `/api/v0/auth/password_constraints/${id}`
  )
}

export const setPassword = (
  token: string,
  password1: string,
  password2: string,
  acceptTos: boolean
) => {
  return http.post<{ detail: string | undefined }>(
    `/api/v0/auth/set_password/`,
    {
      token,
      new_password1: password1,
      new_password2: password2,
      accept_tos: acceptTos,
    }
  )
}

export const sendPasswordReset = (email: string) => {
  return http.post<{ detail: string | undefined }>(
    `/api/v0/auth/send_password_reset/`,
    {
      email,
    }
  )
}

interface ISendEventProps {
  readonly kind: string
  readonly data?: Json
}
export const sendEvent = (payload: ISendEventProps) =>
  http.post('/api/v0/events/create_event/', payload)

export const downloadImportCSVFile = (id: string) => {
  return http.get<{ url: string }>(
    `/api/v0/import-reports/${id}/download_file/`
  )
}

export const getApplicationAlertBanner = () =>
  http.get<IApplicationAlertBannerResponse>(
    '/api/v0/alerts/application-alert-banner/'
  )

export const fetchContacts = (
  cancelToken: CancelToken,
  pageSize: number,
  searchQuery: string,
  pageNumber?: number,
  filter?: IFilterModalState,
  sortType?: string,
  offset?: number,
  order?: 'asc' | 'desc',
  contactFilterId?: number
) =>
  http.get<IPaginatedResponse<IContact[]>>(`/api/v0/contacts/`, {
    params: {
      offset: pageNumber ? (pageNumber - 1) * pageSize : offset,
      limit: pageSize,
      search: searchQuery,
      sort_type: sortType,
      sort_dir: order ?? 'asc',
      contact_filter: contactFilterId,
      ...filter,
    },
    cancelToken,
  })

export const fetchContactCounts = (
  cancelToken: CancelToken,
  searchQuery: string,
  filter: Params,
  contactFilterId?: number
) =>
  http.get<{ taskId: string }>(`/api/v0/contacts/get_contact_counts/`, {
    params: {
      search: searchQuery,
      contact_filter: contactFilterId,
      ...filter,
    },
    cancelToken,
  })

export const fetchContact = (id: string) => {
  return http.get<IFullContact>(`/api/v0/contacts/${id}/`)
}

export const pollArchiveContactsAction = (taskId: string) =>
  http.get<{ status: string; data: IContactCounts; progress: number }>(
    `api/v0/contacts/poll_archive_contacts_action/?task_id=${taskId}`
  )

export const pollGetContactCounts = (
  taskId: string,
  cancelToken: CancelToken
) =>
  http2({
    url: `api/v0/contacts/poll_get_contact_counts/?task_id=${taskId}`,
    method: 'GET',
    cancelToken,
    shape: t.type({
      status: t.string,
      data: t.type({
        activeTest: t.number,
        activeNotTest: t.number,
        archived: t.number,
        total: t.number,
      }),
    }),
  })

export const archiveOrUnarchiveContacts = (
  searchQuery: string,
  filter: Params,
  action: IArchiveActionType,
  source: string,
  contactFilterId?: number
) =>
  http.post<{ taskId: string }>(
    `/api/v0/contacts/batch_archive_or_unarchive/`,
    {
      action,
      source,
      contactFilterId,
    },
    {
      params: {
        search: searchQuery,
        ...filter,
      },
    }
  )

export interface IUpdateContactInfoPanelRequest {
  readonly contactId: IContact['id']
  readonly data: Partial<IContactFormData>
}
export const updateContactInfoPanel = ({
  contactId,
  data,
}: IUpdateContactInfoPanelRequest) =>
  http.patch<IFullContact>(
    `/api/v0/contacts/${contactId}/update_contact_info_panel/`,
    data
  )

export const createContactInfoPanel = (data: Partial<IContactFormData>) => {
  return http.post<IFullContact>(
    `/api/v0/contacts/create_contact_info_panel/`,
    data
  )
}

interface IListLabelNames {
  search: string
  cancelToken: CancelToken
  limit?: number
}
export const listImportLabelNames = ({
  search,
  cancelToken,
  limit = 50,
}: IListLabelNames) => {
  return http.get<IListCustomLabelsResponse>(
    '/api/v0/scheduled-messages/labels/list_all_import_labels_names/',
    { cancelToken, params: { search, limit } }
  )
}
export const getFieldsForInstitution = ({
  institutionId,
  transport,
}: {
  institutionId: string
  transport: TransportId
}) => {
  return http.get<IFetchAllFieldsResponseData>(
    `/api/v0/institution/${institutionId}/get_fields/`,
    {
      params: {
        transport,
      },
    }
  )
}

export const getMappedFieldsForInstitution = ({
  institutionId,
  columns,
  transport,
}: {
  readonly institutionId: string
  readonly columns: string[]
  readonly transport: TransportId
}) => {
  return http.get<IFetchMappedFieldsResponseData>(
    `/api/v0/institution/${institutionId}/get_mapped_fields/`,
    {
      params: {
        columns,
        transport,
      },
    }
  )
}

export const preprocessStudentCSV = (file: File, transport: TransportId) => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('fileName', file.name)
  formData.append('transport', transport)

  return http.post<IPreprocessStudentUploadResponseData>(
    '/api/v0/csv-ingestion/preprocess/',
    formData
  )
}

export const contactUploadRequest = (data: IContactUploadRequestData) => {
  return http.post<IUploadStudentGroupResponseData>(
    `/api/v0/csv-ingestion/upload/`,
    data
  )
}

export interface IUploadRequestData {
  file: File
  fileName: string
}

export const attributeUploadRequest = (formFields: IUploadRequestData) => {
  const form = new FormData()
  Object.entries(formFields).forEach(
    ([key, value]: [string, string | File]) => {
      form.set(key, value)
    }
  )
  return http2({
    url: '/api/v0/upload_attributes/upload/',
    method: 'POST',
    data: form,
    shape: t.type({
      task_id: t.string,
      file_name: t.string,
    }),
  })
}

export const pollAttributeUpload = ({
  taskId,
  fileName,
}: {
  taskId: string
  fileName: string
}) => {
  return http2({
    url: `/api/v0/upload_attributes/poll/?task_id=${taskId}&file_name=${fileName}`,
    method: 'GET',
    shape: t.type({
      task_state: t.string,
      result: et.nullable(
        t.type({
          countAttrsCreated: t.number,
          countAttrsUpdated: t.number,
          countPartialErrors: t.number,
          countFullErrors: t.number,
          fileUrl: t.string,
        })
      ),
    }),
  })
}

export const contactAttributeUploadRequest = (
  formFields: IUploadRequestData
) => {
  const form = new FormData()
  Object.entries(formFields).forEach(
    ([key, value]: [string, string | File]) => {
      form.set(key, value)
    }
  )
  return http2({
    url: '/api/v0/contact-attributes/upload/',
    method: 'POST',
    data: form,
    shape: t.type({
      task_id: t.string,
      file_name: t.string,
    }),
  })
}

export const pollContactAttributeUpload = ({
  taskId,
  fileName,
}: {
  taskId: string
  fileName: string
}) => {
  return http2({
    url: `/api/v0/contact-attributes/poll_upload/?task_id=${taskId}&file_name=${fileName}`,
    method: 'GET',
    shape: t.type({
      task_state: t.string,
      result: et.nullable(
        t.type({
          countAttrsCreated: t.number,
          countAttrsUpdated: t.number,
          countPartialErrors: t.number,
          countFullErrors: t.number,
          fileUrl: t.string,
        })
      ),
    }),
  })
}

export const cancelImport = (importReportId: string) =>
  http.post<{ detail: string }>(`/api/v0/csv-ingestion/cancel/`, {
    importReportId,
  })

export const getAPITokens = () =>
  http.get<ReadonlyArray<IAPIAuthToken>>(`/api/v0/api_tokens/`)

interface ICreateAPIToken {
  readonly note: string
}
export const createAPIToken = ({ note }: ICreateAPIToken) =>
  http.post<IAPIAuthTokenCreate>(`/api/v0/api_tokens/`, { note })

interface IDeleteAPIToken {
  readonly tokenId: string
}
export const deleteAPIToken = ({ tokenId }: IDeleteAPIToken) =>
  http.delete(`/api/v0/api_tokens/${tokenId}/`)

export interface ISalesforceConsumerRequestData {
  consumerKey: string
  consumerSecret: string
  batchSize?: number
}

export const getSalesforceConfig = () =>
  http.get<IConfigureSalesforceResponse>(
    `/api/v0/settings/salesforce/get_sf_consumer/`
  )

export const setSalesforceConfig = ({
  consumerKey,
  consumerSecret,
  batchSize,
}: ISalesforceConsumerRequestData) => {
  return http.post<IConfigureSalesforceResponse>(
    `api/v0/settings/salesforce/configure_sf_consumer/`,
    { salesforceIntegration: { consumerKey, consumerSecret, batchSize } }
  )
}

export const addNoteToContact = ({
  contactId,
  note,
}: {
  contactId: string
  note: IContactNote
}) => http.post<IContactNote[]>(`/api/v0/contacts/${contactId}/add_note/`, note)

interface IEditContactNoteRequest {
  contactId: string
  noteId: string
  newText: string
}
export const editContactNote = ({
  contactId,
  noteId,
  newText,
}: IEditContactNoteRequest) =>
  http.patch<IContactNote[]>(`/api/v0/contacts/${contactId}/edit_note/`, {
    noteId,
    newText,
  })

interface IDeleteContactNoteRequest {
  contactId: string
  noteId: string
}
export const deleteContactNote = ({
  contactId,
  noteId,
}: IDeleteContactNoteRequest) =>
  http.patch<IContactNote[]>(`/api/v0/contacts/${contactId}/delete_note/`, {
    noteId,
  })

interface IInitializeFileUploadRequest {
  readonly name: string
  readonly size: number
  readonly content_type: string
  readonly public: boolean
}
export const initializeFileUploadRequest = (
  data: IInitializeFileUploadRequest
) =>
  http.post<IInitializeFileUploadResponse>(
    '/api/v0/uploads/create_upload_request',
    data
  )

interface IUploadFileToS3 {
  readonly s3URL: string
  readonly formFields: { readonly [key: string]: string | undefined }
  readonly file: File
}
export const uploadFileToS3 = ({
  s3URL,
  formFields,
  file,
}: IUploadFileToS3) => {
  const form = new FormData()
  Object.entries(formFields).forEach(([key, value]) => {
    if (value == null) {
      return
    }
    form.set(key, value)
  })
  form.append('file', file)
  return http.post(s3URL, form)
}

interface ICompleteFileUpload {
  readonly uploadId: IUploadResponse['id']
}
export const completeFileUpload = ({ uploadId }: ICompleteFileUpload) =>
  http.post<IUploadResponse>(`/api/v0/uploads/${uploadId}/complete_upload`)

// 1. get a presigned url to upload our file directly to the s3 bucket
// 2. upload file to that presigned url
// 3. mark in Postgres that the upload completed
export async function uploadImage(file: File) {
  const initialUploadRes = await initializeFileUploadRequest({
    name: file.name,
    content_type: file.type,
    public: true,
    size: file.size,
  })
  const uploadInfo = initialUploadRes.data
  await uploadFileToS3({
    s3URL: uploadInfo.url,
    formFields: uploadInfo.fields,
    file,
  })
  const fileCompleteResult = await completeFileUpload({
    uploadId: uploadInfo.upload.id,
  })
  return fileCompleteResult.data
}

export function startUploadFromUrlPublic(url: string) {
  return http.post<{ taskId: string }>(
    '/api/v0/uploads/start_public_upload_dialog_media_from_url',
    { url }
  )
}

export function checkUploadFromUrlPublicStatus(taskId: string) {
  return http.get<
    | { state: 'pending' }
    | { state: 'success'; data: IUploadResponse }
    | { state: 'failure'; error: string }
  >(`/api/v0/uploads/fetch_url_upload_status/${taskId}/`)
}

function errorMessageFromResponse(err: AxiosError): string {
  let errorMessage = ''
  // display a nicely formed error message for 400 errors, like invalid URLs.
  if (
    err?.response?.status === 400 &&
    // tslint:disable-next-line: no-unsafe-any
    Array.isArray(err.response?.data?.message)
  ) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const errors = err.response?.data as {
      message: {
        loc: string[]
        msg: string
        type: string
      }[]
    }
    errorMessage = errors.message.map(x => x.msg).join('. ')
  }
  return errorMessage || 'An unknown error occurred.'
}

class PollHandle {
  enabled: boolean
  constructor() {
    this.enabled = true
  }

  cancel = () => {
    this.enabled = false
  }
}

/** Initiate an image upload for a given URL and poll for result.
 *
 */
export async function uploadFromURL({
  url,
  onData,
}: {
  url: string
  onData: (params: {
    payload:
      | { type: 'success'; data: IUploadResponse }
      | { type: 'failure'; error: string }
      | { type: 'pending' }
  }) => void
}): Promise<{ handle: PollHandle }> {
  const handle = new PollHandle()
  let taskId: string = ''
  try {
    taskId = (await startUploadFromUrlPublic(url)).data.taskId
  } catch (e) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const errorMessage = errorMessageFromResponse(e as AxiosError)
    onData({ payload: { type: 'failure', error: errorMessage } })
    return {
      handle,
    }
  }

  // start task to poll for results
  setTimeout(async () => {
    while (handle.enabled) {
      const res = (await checkUploadFromUrlPublicStatus(taskId)).data
      if (res.state === 'pending') {
        onData({ payload: { type: 'pending' } })
        await timeout(1000)
      } else if (res.state === 'failure') {
        onData({ payload: { type: 'failure', error: res.error } })
        break
      } else if (res.state === 'success') {
        onData({
          payload: { type: 'success', data: res.data },
        })
        break
      }
    }
  }, 0)

  // return a handle to support canceling the poll
  return { handle }
}

export const getContactLabels = ({
  search = '',
  pageNumber = 1,
  pageSize = 20,
  ordering = '-createdAt',
  counts = false,
}: IFetchContactLabelsRequestData) => {
  const offset = (pageNumber - 1) * pageSize
  return http.get<IPaginatedResponse<IContactLabel[]>>(
    `/api/v0/contact-labels/`,
    {
      params: {
        search,
        ordering,
        offset,
        limit: pageSize,
        counts,
      },
    }
  )
}

export const createContactLabel = (text: string) => {
  return http.post<IContactLabel>('/api/v0/contact-labels/', {
    text,
  })
}

export const deleteContactLabel = (id: string) =>
  http.delete(`/api/v0/contact-labels/${id}/`)

export const updateContactLabel = (id: string, text: string) =>
  http.patch<IContactLabel>(`/api/v0/contact-labels/${id}/`, {
    text,
  })

export const getIntroDialogs = () =>
  http.get<ReadonlyArray<IIntroDialogListItem>>('/api/v0/intro-dialogs/')

const getIntroDialogDetailsPath = (
  kind: IntroDialogKind
): 'unknown-student' | 'web-hook' | 'im-sick' => {
  switch (kind) {
    case 'UNKNOWN_STUDENT': {
      return 'unknown-student'
    }
    case 'WEB_HOOK': {
      return 'web-hook'
    }
    case 'IM_SICK': {
      return 'im-sick'
    }
    default:
      return 'unknown-student'
  }
}

export const getIntroDialogDetails = (kind: IntroDialogKind) => {
  return http.get<{ dialog: IIntroDialog; states: ICampaignScriptStep[] }>(
    `/api/v0/intro-dialogs/${getIntroDialogDetailsPath(kind)}/`
  )
}

export const getDialogStatusById = (taskId: string) =>
  http.get<ICampaignReportPollResponse>(`/api/v0/dialogs/${taskId}/poll/`)

export const generateDialogReport = (
  dialogId: string,
  startDate: null | Date,
  endDate: null | Date,
  commandId: null | number,
  preferences: null | IPreferencesRequestPayload,
  campaignReportType: null | string
) =>
  http.post<IGenerateCampaignResponse>(
    `/api/v0/dialogs/${dialogId}/generate_report/`,
    { startDate, endDate, commandId, preferences, campaignReportType }
  )

export const getCounselors = (
  search?: string,
  filters?: { reason__isnull: boolean }
) =>
  http.get<ICounselorsResponseData>('/api/v0/counselors/', {
    params: {
      search,
      ...filters,
    },
  })

export const updateOrCreateCounselor = (data: ICounselorRequestData) =>
  http.post<ICounselorsResponseData>(
    `/api/v0/counselors/create_or_update_counselor/`,
    data
  )

export const deleteCounselor = (id: number) =>
  http.delete(`/api/v0/counselors/${id}/`)

export const getRules = () =>
  http.get<IEscalationRulesResponseData>(
    '/api/v0/escalation-rules/list_rules_by_type/'
  )

export const getEscalationUnderstandings = () =>
  http.get<IEscalationUnderstandingsResponseData>(
    '/api/v0/escalation-understandings/'
  )

export const updateOrCreateRule = (data: ISaveEscalationRuleRequestData) =>
  http.post<IEscalationRulesResponseData>(
    `/api/v0/escalation-rules/settings/create_or_update_rule/`,
    data
  )

export const deleteRule = (id: number) =>
  http.delete(`api/v0/escalation-rules/${id}/`)

export const prioritizeRules = (
  data: IEscalationPrioritizeRulesRequestData,
  cancelToken: CancelToken
) =>
  http.post<IEscalationRuleResponseData[]>(
    '/api/v0/escalation-rules/settings/update_rule_prioritization/',
    data,
    { cancelToken }
  )

export const fetchInstitutionAttributes = ({
  search = '',
  pageNumber = 1,
  pageSize = 20,
  ordering = 'name',
  counts = false,
  type = '',
  cancelToken,
  locationsUsed = false,
}: Omit<IGetAttributesRequestData, 'omitAuthEmail'>) => {
  const offset = (pageNumber - 1) * pageSize
  return http.get<IInstitutionAttributesResponseData>(
    `/api/v0/org-attributes/`,
    {
      cancelToken,
      params: {
        search,
        ordering,
        offset,
        limit: pageSize,
        counts,
        type: type.toUpperCase(),
        locationsUsed,
      },
    }
  )
}

export const fetchContactAttributes = ({
  search = '',
  pageNumber = 1,
  pageSize = 20,
  cancelToken,
  omitAuthEmail = false,
  locationsUsed = false,
}: Omit<
  IGetAttributesRequestData,
  'pageSize' | 'ordering' | 'counts' | 'type'
> & { pageSize?: number | null }) => {
  if (!pageSize) {
    return http.get<IContactAttributesResponseData>(
      `api/v0/contact-attributes/`,
      {
        cancelToken,
        params: {
          search,
          locationsUsed,
          omitAuthEmail,
        },
      }
    )
  }
  const offset = (pageNumber - 1) * pageSize
  return http.get<IContactAttributesResponseData>(
    `api/v0/contact-attributes/`,
    {
      cancelToken,
      params: {
        search,
        offset,
        limit: pageSize,
        locationsUsed,
        omitAuthEmail,
      },
    }
  )
}

export const fetchInstitutionAttribute = (id: number) =>
  http.get<IInstitutionAttributeRetrieveResponseData>(
    `/api/v0/org-attributes/${id}/`
  )

const ChannelShape = t.union([
  t.literal('web_bot'),
  t.literal('sms'),
  t.literal('slack'),
  t.literal('facebook'),
])

export const fetchContactAttribute = (id: number) =>
  http.get<IContactAttributeResponseData>(`/api/v0/contact-attributes/${id}/`)

export const fetchInstitutionAttributeReferences = (
  id: number
): Promise<Either<Http2ErrorUnion, IAttributeRef[]>> =>
  http2({
    url: `/api/v0/org-attributes/${id}/get_references/`,
    method: 'GET',
    shape: t.array(
      t.type({
        id: t.string,
        type: withFallback(
          t.union([
            t.literal('understanding'),
            t.literal('dialog'),
            t.literal('escalation_rule'),
            t.literal('contact_filter'),
            t.literal('unknown'),
          ]),
          'unknown'
        ),
        sample_question: t.union([t.string, t.null]),
        human_name: t.union([t.string, t.null]),
      })
    ),
  })

export const fetchContactAttributeReferences = (
  id: number
): Promise<Either<Http2ErrorUnion, IAttributeRef[]>> =>
  http2({
    url: `/api/v0/contact-attributes/${id}/get_references/`,
    method: 'GET',
    shape: t.array(
      t.type({
        id: t.string,
        type: t.union([
          t.literal('understanding'),
          t.literal('dialog'),
          t.literal('escalation_rule'),
          t.literal('contact_filter'),
        ]),
        sample_question: t.union([t.string, t.null]),
        human_name: t.union([t.string, t.null]),
      })
    ),
  })

export const updateContactAttribute = async (
  data: ISetContactAttributeRequestData
): Promise<AxiosResponse<IContactAttributeResponseData>> => {
  if (data.id) {
    return await http.patch(`/api/v0/contact-attributes/${data.id}/`, data)
  } else {
    return await http.post(`/api/v0/contact-attributes/`, data)
  }
}

export const deleteContactAttribute = (id: number) =>
  http2({
    url: `/api/v0/contact-attributes/${id}`,
    method: 'DELETE',
    shape: t.type({
      task_id: t.string,
    }),
  })

export const getContactChannels = (id: string) =>
  http2({
    url: `/api/v0/contacts/${id}/get_channels`,
    method: 'GET',
    shape: t.type({
      channels: t.array(ChannelShape),
    }),
  })

export const pollDeleteContactAttribute = (taskId: string) =>
  http2({
    url: `/api/v0/contact-attributes/poll/?task_id=${taskId}`,
    method: 'GET',
    shape: t.type({
      task_status: t.string,
      detail: t.string,
      result: t.union([t.string, t.null]),
    }),
  })

export const updateInstitutionAttribute = async (
  data: IInstitutionAttributeRequestData
) => {
  if (data.id) {
    return await http.patch<IInstitutionAttributeResponseData>(
      `/api/v0/org-attributes/${data.id}/`,
      data
    )
  } else {
    return await http.post<IInstitutionAttributeResponseData>(
      `/api/v0/org-attributes/`,
      data
    )
  }
}

export const deleteInstitutionAttribute = (id: number) =>
  http.delete(`/api/v0/org-attributes/${id}`)

export const updateAudienceSpecificOrgAttributes = (
  id: number,
  values: {
    contact_filter: number
    institution_attribute: number
    value: string
  }[]
) => {
  return http2({
    url: `/api/v0/org-attributes/${id}/update_audience_specific_org_attrs/`,
    method: 'POST',
    data: values,
    shape: t.type({
      id: t.number,
      institution: t.number,
      name: t.string,
      data_type: fromEnum('InstitutionAttributeType', InstitutionAttributeType),
      value: t.string,
      audience_specific_values: t.union([
        t.undefined,
        t.array(
          t.type({
            contact_filter_id: t.number,
            contact_filter_name: t.string,
            value: t.string,
            priority: t.number,
          })
        ),
      ]),
    }),
  })
}

export const fetchInstitutionAttributeCountsByType = () =>
  http.get<IAttributeTypeCounter>(
    `api/v0/org-attributes/get_inst_attr_counts_by_type/`
  )

const AttributeType = {
  id: t.number,
  name: t.string,
  data_type: fromEnum('ContactAttributeType', ContactAttributeType),
  requires_auth: t.union([t.boolean, t.undefined]),
  include_in_escalations: t.union([t.boolean, t.undefined]),
  value: t.union([t.string, t.undefined]),
  multi_choices: t.union([
    t.undefined,
    t.array(
      t.type({
        id: t.number,
        value: t.string,
      })
    ),
  ]),
}

export const updateAttributeMapping = (
  data: IUpdateAttributeMappingRequestData
) => {
  const { target_institution_id, source_institution_id, mappings } = data
  return http2({
    url: '/api/v0/attribute-mapper/update_mappings/',
    method: 'POST',
    data: {
      target_institution_id,
      source_institution_id,
      mappings,
    },
    shape: t.type({
      contact: t.array(
        t.type({
          source_attribute: t.type(AttributeType),
          target_attribute: t.type(AttributeType),
        })
      ),
      institution: t.array(
        t.type({
          source_attribute: t.type(AttributeType),
          target_attribute: t.type(AttributeType),
        })
      ),
    }),
  })
}

export const createNewAttributesFromMapper = (
  data: ICreateNewAttributesFromMapperRequestData
) => {
  const {
    source_institution,
    target_institution,
    attribute_type,
    attributes,
  } = data
  return http2({
    url: '/api/v0/attribute-mapper/create_new_attributes/',
    method: 'POST',
    data: {
      source_institution,
      target_institution,
      attribute_type,
      attributes,
    },
    shape: t.type({
      mappings: t.array(
        t.type({
          source_attribute: t.type(AttributeType),
          target_attribute: t.type(AttributeType),
        })
      ),
      target_attributes: t.array(t.type(AttributeType)),
    }),
  })
}

export const fetchAttributeMappings = (
  data: IGetAttributeMappingsRequestData
) => {
  const {
    source_institution,
    target_institution,
    attribute_type,
    attr_ids,
  } = data
  return http2({
    url: '/api/v0/attribute-mapper/get_mappings/',
    method: 'GET',
    params: {
      source_institution,
      target_institution,
      attribute_type,
      attr_ids: attr_ids ?? [],
    },
    shape: t.type({
      source_attrs: t.array(t.type(AttributeType)),
      target_attrs: t.array(t.type(AttributeType)),
      mappings: t.array(
        t.type({
          source_attribute: t.type(AttributeType),
          target_attribute: t.type(AttributeType),
        })
      ),
    }),
  })
}

export const fetchTopLevelContactFields = () =>
  http.get<ITopLevelContactFieldsResponseData>(
    `api/v0/contact-fields/list-contact-fields/`
  )

export const fetchAllAttributesAndFields = (
  locationsUsed = false,
  fromScriptLibrary = false
) =>
  http2({
    url: '/api/v0/contact-attributes/get_all_attributes_and_fields/',
    method: 'GET',
    params: {
      locationsUsed: String(locationsUsed),
      fromScriptLibrary: String(fromScriptLibrary),
    },
    shape: t.type({
      institutionAttributes: t.array(
        t.type({
          id: t.number,
          name: t.string,
          institution: t.number,
          value: t.string,
          data_type: fromEnum(
            'InstitutionAttributeType',
            InstitutionAttributeType
          ),
          understandings_count: t.union([t.undefined, t.number]),
        })
      ),
      contactAttributes: t.array(
        t.type({
          id: t.number,
          institution: t.number,
          name: t.string,
          description: t.union([t.string, t.null]),
          requires_auth: t.boolean,
          include_in_escalations: t.boolean,
          data_type: fromEnum('ContactAttributeType', ContactAttributeType),

          topLevelField: t.union([t.undefined, t.boolean]),
          multi_choices: t.union([
            t.undefined,
            t.array(
              t.type({
                id: t.number,
                value: t.string,
                order: t.union([t.undefined, t.number]),
              })
            ),
          ]),
          understandings_count: t.union([t.undefined, t.number]),
        })
      ),
      topLevelFields: t.record(
        t.string,
        fromEnum('ContactAttributeType', ContactAttributeType)
      ),
    }),
  })

export const fetchSaveableTopLevelContactFields = (
  search: string | undefined = undefined
) =>
  http2({
    url: search
      ? `api/v0/contact-fields/list-top-level-fields/?search=${search}`
      : `api/v0/contact-fields/list-top-level-fields/`,
    method: 'GET',
    shape: t.array(
      t.type({
        id: t.number,
        name: t.string,
        institution: t.number,
        description: t.union([t.string, t.null]),
        requires_auth: t.boolean,
        include_in_escalations: t.boolean,
        data_type: fromEnum('ContactAttributeType', ContactAttributeType),
        topLevelField: t.union([t.boolean, t.undefined]),
      })
    ),
  })

export const fetchDialogLabels = () =>
  http.get<IDialogLabel[]>('/api/v0/dialog-labels/')

export const createDialogLabel = (newLabelName: string) =>
  http.post<IDialogLabel>('/api/v0/dialog-labels/', { name: newLabelName })

export const deleteDialogLabel = (labelId: number) =>
  http.delete(`/api/v0/dialog-labels/${labelId}/`)

export const updateDialogLabel = (labelId: number, name: string) =>
  http.patch<IDialogLabel>(`/api/v0/dialog-labels/${labelId}/`, { name })

export const bulkApplyDialogLabel = (
  dialogIds: string[],
  labelsToAdd: number[],
  labelsToRemove: number[],
  labelsToCreate: string[]
) =>
  http.patch<{ [dialogId: string]: IDialogLabel[] }>(
    '/api/v0/dialog-labels/apply/',
    {
      dialogIds,
      labelsToAdd,
      labelsToRemove,
      labelsToCreate,
    }
  )

export const createUnderstandingKnowledgeEditor = (params: {
  readonly summaryQuestion: string
  readonly topic: {
    readonly first: string
    readonly second: string
  }
  readonly answers: { answer: string; approved: boolean }[]
  readonly questions: { question: string }[]
}) =>
  http.post<{
    id: string
    topic: string
    category: string
    subCategory: string
    hasApprovedAnswer: boolean
    approvals: { approvedByName?: string; approvedAt?: string }
    sampleAnswer: string | null
    sampleQuestion: string | null
    fuzzyQuestionCount: number
  }>(`/api/v0/understandings/create_new_understanding`, params)

export const fetchSidebarUnderstanding = ({
  understandingId,
}: {
  understandingId: string
}) =>
  http.get<{
    suggestedAnswers: ReadonlyArray<{
      id: number
      answer: string
      modified: string
    }>
    answers: {
      id: number
      answer?: string
      order: number
      dialogId: string | null
      dialogName: string | null
      generative: boolean
      lastEditedBy?: {
        id: string
        name: string
      }
      contactFilter?: {
        id: number
        name: string
      }
      approved: boolean
      modified: string
      workflowSteps: ICampaignScriptStep[]
    }[]
    institutionQuestions?: ReadonlyArray<{
      id: number
      question: string
    }>
    globalQuestions?: ReadonlyArray<{
      id: number
      enabled: boolean
      question: string
    }>
    topic: string
    category: string
    subCategory: string
    sampleQuestion: string
    institutionAbbreviation?: string
  }>(`/api/v0/understandings/${understandingId}/fetch_sidebar/`)

export const addOrganizationQuestion = ({
  understandingId,
  question,
}: {
  understandingId: string
  question: string
}) =>
  http.post<{ id: number; question: string }>(
    `/api/v0/understandings/${understandingId}/add_institution_question/`,
    { question }
  )

export const updateOrganizationQuestion = ({
  questionId,
  question,
}: {
  questionId: number
  question: string
}) =>
  http.post<{ id: number; question: string }>(
    `/api/v0/understandings/update_institution_question/${questionId}/`,
    { question }
  )

export const deleteOrganizationQuestion = ({
  questionId,
}: {
  questionId: number
}) =>
  http.delete(
    `/api/v0/understandings/delete_institution_question/${questionId}/`
  )

export const reorderAnswers = ({
  understandingId,
  answerIds,
}: {
  understandingId: string
  answerIds: number[]
}) =>
  http2({
    url: `/api/v0/understandings/${understandingId}/order/`,
    method: 'POST',
    data: {
      answer_ids: answerIds,
    },
    shape: t.array(
      t.type({
        id: t.number,
        order: t.number,
      })
    ),
  })

export const updateGlobalQuestion = ({
  questionId,
  enabled,
}: {
  questionId: number
  enabled: boolean
}) =>
  http.post<{ id: number; question: string; enabled: boolean }>(
    `/api/v0/understandings/update_global_question/${questionId}/`,
    {
      enabled,
    }
  )

export const updateUnderstandingApproval = ({
  answerId,
  approved,
}: {
  answerId: number
  approved: boolean
}) =>
  http.patch<{
    // Answer response.
    id: number
    answer?: string
    dialogId: string | null
    dialogName: string | null
    generative: boolean
    order: number
    approved: boolean
    modified: string
    lastEditedBy?: {
      id: string
      name: string
    }
    workflowSteps: ICampaignScriptStep[]
  }>(`/api/v0/understandings/answers/${answerId}/approval/`, { approved })

export const understandingCopyGlobalAnswersToInstitution = ({
  understandingId,
  global_answer_ids,
  approveAll = false,
}: {
  understandingId: string
  global_answer_ids: ReadonlyArray<number>
  approveAll?: boolean
}) =>
  http.post<{
    institutionAnswers: [
      {
        id: number
        answer?: string
        dialogId: string | null
        dialogName: string | null
        generative: boolean
        order: number
        approved: boolean
        modified: string
        lastEditedBy: {
          id: string
          name: string
        }
        workflowSteps: ICampaignScriptStep[]
      }
    ]
  }>(`/api/v0/understandings/${understandingId}/copy_global_answers/`, {
    answer_ids: global_answer_ids,
    approve_all: approveAll,
  })

export const understandingDeleteAnswer = ({ answerId }: { answerId: number }) =>
  http.post(`/api/v0/understandings/answers/${answerId}/delete/`)

export const understandingCreateAnswer = ({
  understandingId,
  answer,
  approved,
  dialogId,
  generative,
  contactFilter,
  order,
  genAITransactionId,
}: {
  understandingId: string
  answer?: string
  dialogId?: string
  generative: boolean
  order?: number
  approved: boolean
  contactFilter?: number
  genAITransactionId?: number
}) =>
  http.post<{
    id: number
    answer?: string
    dialogId: string | null
    dialogName: string | null
    generative: boolean
    approved: boolean
    order: number
    modified: string
    lastEditedBy: {
      id: string
      name: string
    }
    contactFilter?: {
      id: number
      name: string
    }
    genAITransactionId?: number
    workflowSteps: ICampaignScriptStep[]
  }>(`/api/v0/understandings/${understandingId}/answers/add_answer_v2/`, {
    answer,
    approved,
    contact_filter: contactFilter,
    dialogId,
    generative,
    order,
    genAITransactionId,
  })

export const understandingUpdateAnswer = ({
  answerId,
  answer,
  dialogId,
  generative,
  approved,
  contactFilter,
  order,
  genAITransactionId,
}: {
  answerId: number
  answer?: string
  dialogId?: string
  generative: boolean
  approved: boolean
  contactFilter?: number
  order?: number
  genAITransactionId?: number
}) =>
  http.patch<{
    id: number
    answer?: string
    dialogId: string | null
    dialogName: string | null
    generative: boolean
    order: number
    genAITransactionId: number
    approved: boolean
    modified: string
    lastEditedBy: {
      id: string
      name: string
    }
    workflowSteps: ICampaignScriptStep[]
  }>(`/api/v0/understandings/answers/${answerId}/update/`, {
    answer,
    approved,
    contact_filter: contactFilter,
    dialogId,
    generative,
    order,
    genAITransactionId,
  })

export interface ITemplateCollection {
  id: number
  name: string
  description: string
  count: number
  shared_institutions: { id: string; displayName: string }[]
}

export const createTemplateCollection = (data: {
  name: string
  description: string
}) => http.post<ITemplateCollection>('/api/v0/dialog-collections/', data)

export const deleteTemplateCollection = (id: number) =>
  http.delete(`/api/v0/dialog-collections/${id}/`)

export const listTemplateCollections = (includeSharedInstitutionData = true) =>
  http.get<
    {
      id: number
      name: string
      description: string
      count: number
      shared_institutions: Array<{
        id: string
        displayName: string
      }>
    }[]
  >(
    `/api/v0/dialog-collections/?includeSharedInstitutionData=${includeSharedInstitutionData}`
  )

export const updateTemplateCollections = (
  collectionsToAdd: number[],
  collectionsToRemove: number[],
  templateIds: string[]
) =>
  http.patch(`/api/v0/dialog-collections/update-templates/`, {
    collectionsToAdd,
    collectionsToRemove,
    templateIds,
  })

export const updateTemplateData = (
  id: number,
  data: {
    id: number
    name: string
    description: string
    count: number
  }
) =>
  http2({
    url: `/api/v0/dialog-collections/${id}/`,
    method: 'PATCH',
    data,
    shape: t.type({
      id: t.number,
      name: t.string,
      description: t.string,
      count: t.number,
    }),
  })

export const retrieveTemplateCollection = (id: number) =>
  http2({
    url: `/api/v0/dialog-collections/${id}/`,
    method: 'GET',
    shape: t.type({
      id: t.number,
      name: t.string,
      description: t.string,
      count: t.number,
      shared_institutions: t.array(t.string),
    }),
  })

export const shareTemplateCollection = ({
  collectionId,
  institutionIds,
  allInstitutions,
  shareType,
}: {
  readonly collectionId: number
  readonly institutionIds: readonly string[]
  readonly allInstitutions: boolean
  readonly shareType: 'share' | 'hide'
}) =>
  http2({
    url: `/api/v0/dialog-collections/${collectionId}/share/`,
    method: 'POST',
    data: {
      all_institutions: allInstitutions,
      institution_ids: institutionIds,
      share_type: shareType,
    },
    shape: t.unknown,
  })

export const fetchAdmithubTags = () =>
  http.get<IDialogLabel[]>('/api/v0/dialog-labels/script_library/')

export const fetchTraySolutions = () =>
  http.get<Array<ITraySolutionResponseData>>('api/v0/tray/list_solutions/')

export const fetchTraySolutionInstances = (solutionIds: string[]) =>
  http.post<Array<ITraySolutionInstanceResponseData>>(
    'api/v0/tray/list_solution_instances/',
    { solutionIds }
  )

export const createSolutionInstance = ({
  name,
  solutionId,
  isSFTP,
}: ICreateSolutionInstanceRequestData) =>
  http.post<ICreateSolutionInstanceResponseData>(
    'api/v0/tray/create_solution_instance/',
    { name, solutionId, isSFTP }
  )

export const setSolutionInstanceEnabled = ({
  name,
  solutionInstanceId,
  enabled,
  solutionIds,
}: IToggleSolutionInstanceEnabledRequestData) =>
  http.post<ITraySolutionInstanceResponseData>(
    'api/v0/tray/toggle_solution_instance_enabled/',
    { name, solutionInstanceId, enabled, solutionIds }
  )

export const updateSolutionInstance = (solutionInstanceId: string) =>
  http.post<IUpdateSolutionInstanceResponseData>(
    'api/v0/tray/update_solution_instance/',
    { solutionInstanceId }
  )

export const deleteSolutionInstance = (
  solutionInstanceId: string,
  solutionIds: string[]
) =>
  http.post<Array<ITraySolutionInstanceResponseData>>(
    'api/v0/tray/delete_solution_instance/',
    { solutionInstanceId, solutionIds }
  )

export const downloadSlateCSVTemplate = () =>
  http.get<{ data: string }>('api/v0/tray/download_slate_template/')

export const createSFTPUser = (publicKey: string | undefined = undefined) =>
  http.post<{
    user_name: string
    password: string
    public_key: string | null
  }>('api/v0/sftp/create_user/', {
    public_key: publicKey,
  })

export const getSFTPUser = () =>
  http.get<
    | {
        user_name: string
        password: string
        public_key: string | null
      }
    | undefined
  >('api/v0/sftp/get_user/')

export const deleteSFTPUser = () => http.delete('api/v0/sftp/delete_user/')

export const generateContactList = (
  searchQuery: string,
  filter: Params,
  importLabels?: string[],
  contactFilterId?: number | string | null,
  contactFilterObj?: IContactFilterRequestData,
  useCachedAudienceData: boolean = false,
  preferences?: IPreferencesRequestPayload
) => {
  return http.post<{ taskId: string }>(
    `api/v0/contacts/generate_contact_csv/`,
    {
      importLabels,
      contactFilterId,
      contactFilterObj,
      useCachedAudienceData,
      preferences,
    },
    {
      params: {
        search: searchQuery,
        contact_filter: contactFilterId || undefined,
        ...filter,
      },
    }
  )
}

export const pollDownloadContactList = (taskId: string) =>
  http.get<{ status: string; report_url: string }>(
    `api/v0/contacts/poll_generate_contact_csv/?task_id=${taskId}`
  )

export const fetchIntegrationErrors = (pageNumber: number, search?: string) => {
  return http.get<IIntegrationErrorsResponse>('api/v0/integrations/errors/', {
    params: {
      limit: settings.INTEGRATION_ERRORS_PER_PAGE,
      offset: (pageNumber - 1) * settings.INTEGRATION_ERRORS_PER_PAGE,
      search,
    },
  })
}

export const downloadIntegrationErrors = (
  startDate: moment.Moment | null,
  endDate: moment.Moment | null,
  search?: string
) => {
  let params = {}
  const dateFormat = 'YYYY-MM-DD'

  if (startDate) {
    params = { ...params, 'start-date': startDate.format(dateFormat) }
  }
  if (endDate) {
    params = { ...params, 'end-date': endDate.format(dateFormat) }
  }
  if (search) {
    params = { ...params, search }
  }

  return http2({
    url: 'api/v0/integrations/errors/download_logs/',
    method: 'GET',
    params,
    shape: t.type({
      task_id: t.string,
    }),
  })
}

export const pollDownloadIntegrationErrors = (taskId: string) => {
  return http2({
    url: 'api/v0/integrations/errors/poll_download_logs/',
    method: 'GET',
    params: {
      task_id: taskId,
    },
    shape: t.type({
      task_status: t.string,
      result: t.string,
      detail: t.string,
      data: t.type({
        url: et.nullable(t.string),
      }),
    }),
  })
}

export const FilterCounts = t.type({
  activeNowForMe: t.number,
  pastChats: t.number,
  flagged: withFallback(t.number, 0),
})
export const conversationsV2GetFilterCounts = () =>
  http2({
    url: '/api/v0/conversations-v2/get_filter_counts',
    method: 'GET',
    shape: FilterCounts,
  })

export const ConvListContact = t.type({
  id: t.string,
  name: et.nullable(t.string),
  preferredName: et.nullable(t.string),
  transport: transportSchema,
  transports: t.array(transportSchema),
  activeUntil: et.nullable(t.string),
  lastMessage: et.nullable(t.string),
  lastMessageAt: et.nullable(t.string),
  lastMessageTransport: et.nullable(t.keyof(TransportEnum)),
  conversationReadTimestamp: et.nullable(t.string),
  lastIncomingMessageAt: et.nullable(t.string),
  tags: t.array(t.type({ id: t.string, name: t.string, resolved: t.boolean })),
  dialogIds: t.array(t.string),
  scheduledMessageIds: t.array(t.string),
  region: et.nullable(t.string),
  crmId: et.nullable(t.string),
  phone: et.nullable(t.string),
  email: et.nullable(t.string),
  frozenUntil: et.nullable(t.string),
  flagged: t.union([t.boolean, t.undefined]),
  escalated: t.union([t.boolean, t.undefined]),
  hasUnreadMessages: withFallback(t.boolean, false),
  unreadMessagesStatusByChannel: t.record(
    t.string,
    withFallback(t.boolean, false)
  ),
})

export type ConversationV2ListContactFilterParams = {
  readonly pinned?: boolean
  readonly offset?: number
  readonly search?: string
  readonly filters?: {
    readonly transports: readonly TransportId[]
    readonly campaignId?: string | null
    readonly scriptId?: string | null
    readonly sidebarFilter: SidebarFilter
    readonly audienceIds: readonly string[]
    readonly status?: StatusFilter
  }
}

export const conversationsV2ListContacts = ({
  params,
  cancelToken,
}: {
  readonly params: ConversationListFilterParams
  readonly cancelToken: CancelToken
}) =>
  http2({
    url: '/api/v0/conversations-v2/list_contacts',
    method: 'GET',
    params: {
      offset: params.offset,
      transport: params?.transport ?? undefined,
      scheduled_message: params?.campaignId ?? undefined,
      dialog: params?.scriptId ?? undefined,
      sidebar_filter: params?.sidebarFilter ?? undefined,
      audience: params?.audience,
      search: params.search ?? undefined,
      pinned: params.pinned,
      status: params?.status ?? undefined,
    },
    cancelToken,
    shape: t.type({
      next_offset: et.nullable(t.number),
      contacts: t.array(ConvListContact),
      total_count: t.number,
    }),
  })

export const conversationsV2ListTogglePinConversation = ({
  pin,
  conversationId,
}: {
  readonly pin: boolean
  readonly conversationId: string
}) =>
  http2({
    url: '/api/v0/conversations-v2/pin_conversation',
    method: 'PUT',
    data: {
      pin,
      conversation_id: conversationId,
    },
    shape: t.unknown,
  })

const Media = t.type({
  url: t.string,
  contentType: et.nullable(t.string),
})

const KnowledgeReviewMarkShape = t.type({
  reason: fromEnum('KnowledgeReviewReason', KnowledgeReviewReason),
  note: et.nullable(t.string),
  reviewed: et.nullable(t.boolean),
})

const MessageIncoming = t.type({
  direction: t.literal('incoming'),
  id: t.string,
  text: t.string,
  flagged: t.union([t.boolean, t.undefined]),
  escalated: t.union([t.boolean, t.undefined]),
  media: et.nullable(Media),
  mediaFiles: t.union([t.array(Media), t.undefined]),
  sourceText: et.nullable(t.string),
  createdAt: t.string,
  knowledgeReviewMark: withFallback(
    et.nullable(KnowledgeReviewMarkShape),
    null
  ),
  channel: ChannelShape,
})

const FeedbackShape = t.type({
  rating: t.string,
  comment: et.nullable(t.string),
  createdAt: t.string,
})

const MessageOutgoing = t.type({
  direction: t.literal('outgoing'),
  id: t.string,
  inflightId: et.nullable(t.string),
  text: t.string,
  media: et.nullable(Media),
  mediaFiles: t.union([t.array(Media), t.undefined]),
  sourceText: et.nullable(t.string),
  createdAt: t.string,
  sender: withFallback(
    t.union([
      t.type({
        kind: t.literal('bot'),
      }),
      t.type({
        kind: t.literal('email'),
      }),
      t.type({
        kind: t.literal('college_staff'),
        name: et.nullable(t.string),
        firstName: et.nullable(t.string),
        lastName: et.nullable(t.string),
      }),
      t.type({
        kind: t.literal('admithub_staff'),
      }),
      t.type({
        kind: t.literal('unknown'),
      }),
    ]),
    { kind: 'unknown' }
  ),
  deliveryFailure: et.nullable(t.string),
  origin: withFallback(
    et.nullable(
      t.union([
        t.type({
          kind: t.literal('understanding'),
          understandingId: t.string,
          generativeTransactionId: t.union([t.undefined, t.number, t.null]),
        }),
        t.type({
          kind: t.literal('fallback'),
          understandingId: t.string,
          generativeTransactionId: t.union([t.undefined, t.number, t.null]),
        }),
        t.type({
          kind: t.literal('dialog'),
          dialogId: t.string,
        }),
        t.type({
          kind: t.literal('ai_assisted_live_chat'),
          generativeTransactionId: t.number,
        }),
      ])
    ),
    null
  ),
  knowledgeReviewMark: withFallback(
    et.nullable(KnowledgeReviewMarkShape),
    null
  ),
  feedback: withFallback(et.nullable(FeedbackShape), null),
  channel: ChannelShape,
  generativeAIAssisted: t.boolean,
})

export const Message = t.union([MessageIncoming, MessageOutgoing])

export const Escalation = t.type({
  counselorNames: et.nullable(t.array(t.string)),
  counselorName: t.union([et.nullable(t.string), t.undefined]),
  createdAt: t.string,
  emails: et.nullable(t.array(t.string)),
  email: t.union([et.nullable(t.string), t.undefined]),
  id: t.string,
  message: et.nullable(MessageIncoming),
  channel: ChannelShape,
})

const LiveChatUser = t.type({
  id: et.nullable(t.string),
  name: et.nullable(t.string),
})

const MessagingDisabled = t.type({
  kind: t.literal('messagingDisabled'),
  channel: et.nullable(t.string),
})

const LiveChatActive = t.type({
  kind: t.literal('liveChatActive'),
  user: LiveChatUser,
  timeRemainingSeconds: t.number,
  channel: et.nullable(t.string),
})

export const ChatState = withFallback(
  t.union([
    MessagingDisabled,
    t.type({ kind: t.literal('liveChatOff'), channel: et.nullable(t.string) }),
    LiveChatActive,
    t.type({
      kind: t.literal('unknown'),
      channel: et.nullable(t.string),
    }),
  ]),
  { kind: 'unknown', channel: 'unknown' }
)

export const conversationsV2Detail = ({
  contactId,
  offset,
  oldest,
  latest,
  messageId,
  pageSize,
  channel,
}: {
  readonly contactId: string
  readonly offset?: number | null
  readonly oldest?: string | null
  readonly latest?: string | null
  readonly messageId?: string | null
  readonly pageSize?: number | null
  readonly channel: string
}) =>
  http2({
    url: `/api/v0/conversations-v2/conversation/${contactId}/`,
    method: 'GET',
    params: {
      offset: offset ?? undefined,
      oldest: oldest ?? undefined,
      latest: latest ?? undefined,
      messageId: messageId ?? undefined,
      pageSize: pageSize ?? undefined,
      channel,
    },
    shape: t.type({
      hasMoreNewer: et.nullable(t.boolean),
      hasMoreOlder: et.nullable(t.boolean),
      contact: t.type({
        id: t.string,
        name: et.nullable(t.string),
        preferredName: et.nullable(t.string),
        phone: et.nullable(t.string),
        email: et.nullable(t.string),
        lastMessageAt: et.nullable(t.string),
        translationUsed: t.boolean,
        chatState: ChatState,
        conversationReadTimestamp: et.nullable(t.string),
        lastUnreadMessageId: et.nullable(t.string),
        unreadMessagesStatusByChannel: t.record(
          t.string,
          withFallback(t.boolean, false)
        ),
        channels: t.array(ChannelShape),
      }),
      escalations: t.array(Escalation),
      resets: t.array(
        t.type({
          id: t.string,
          createdAt: t.string,
          channel: ChannelShape,
        })
      ),
      messages: t.array(Message),
    }),
  })

export const conversationsV2MessagesCountInRange = ({
  contactId,
  timestampFrom,
  timestampTo,
}: {
  readonly contactId: string
  readonly timestampFrom: string
  readonly timestampTo: string
}) =>
  http2({
    url: `/api/v0/conversations-v2/messages_count_in_range/${contactId}/`,
    method: 'POST',
    data: {
      timestampFrom,
      timestampTo,
    },
    shape: t.number,
  })

export const conversationsV2StartLiveChat = ({
  contactId,
  channel,
}: {
  readonly contactId: string
  readonly channel: string
}) =>
  http2({
    url: `/api/v0/conversations-v2/start_live_chat`,
    method: 'POST',
    data: {
      contactId,
      channel,
    },
    shape: t.type({
      chatState: ChatState,
      genAIResponseForFallback: t.type({
        shouldGenerateResponse: t.boolean,
        lastestFallbackSmsLogID: t.union([t.string, t.null]),
      }),
    }),
  })

export const conversationsV2CancelLiveChat = ({
  contactId,
  channel,
}: {
  readonly contactId: string
  readonly channel: string
}) =>
  http2({
    url: `/api/v0/conversations-v2/cancel_live_chat`,
    method: 'POST',
    data: {
      contactId,
      channel,
    },
    shape: t.type({
      chatState: ChatState,
    }),
  })

export const conversationsV2GetFlaggedMessages = ({
  params,
  cancelToken,
}: {
  readonly params: { contactId: string }
  readonly cancelToken: CancelToken
}) =>
  http2({
    url: `/api/v0/conversations-v2/get_flagged_messages/${params.contactId}/`,
    method: 'GET',
    shape: t.type({
      messages: t.array(
        t.type({
          id: t.string,
          createdAt: t.string,
          channel: et.nullable(t.string),
        })
      ),
      count: t.number,
    }),
    cancelToken,
  })

export const conversationsV2SendMessage = ({
  contactId,
  message,
  inflightMessageId,
  uploadId,
  channel,
  generativeAIAssisted,
  genAItransactionId,
}: {
  readonly contactId: string
  readonly message: string
  readonly inflightMessageId: string
  readonly uploadId?: number
  readonly channel: string
  readonly generativeAIAssisted?: boolean
  readonly genAItransactionId?: number
}) =>
  http2({
    url: `/api/v0/conversations-v2/send_message`,
    method: 'POST',
    data: {
      contactId,
      message,
      inflightMessageId,
      uploadId,
      channel,
      generativeAIAssisted,
      genAItransactionId,
    },
    shape: t.type({
      chatState: ChatState,
      sendResult: t.union([
        t.type({
          ok: t.literal(true),
        }),
        t.type({
          ok: t.literal(false),
          message: t.string,
        }),
      ]),
    }),
  })

const AttributeNameMap = t.type({
  institution: et.nullable(t.record(t.string, t.string)),
  contact: et.nullable(t.record(t.string, t.string)),
  toplevel: et.nullable(t.record(t.string, t.string)),
})

const ContactFilter = t.type({
  id: t.string,
  name: et.nullable(t.string),
})

export type UnderstandingOriginType = t.TypeOf<typeof UnderstandingOrigin>

const ScriptOrigin = t.type({
  kind: t.literal('script'),
  id: t.string,
  name: et.nullable(t.string),
  internal: withFallback(t.boolean, false),
})

export type ScriptOriginType = t.TypeOf<typeof ScriptOrigin>

const EmbeddedDataSource = t.type({
  context_id: t.number,
  answer_text: et.nullable(t.string),
  understanding_mongo_id: et.nullable(t.string),
  source_id: et.nullable(t.number),
  source_title: et.nullable(t.string),
  source_url: et.nullable(t.string),
  page_numbers: et.nullable(t.array(t.union([t.number, t.string]))),
})

const UnderstandingOrigin = t.type({
  kind: t.literal('understanding'),
  id: t.string,
  fallback: t.boolean,
  sampleQuestion: t.string,
  topic: t.string,
  generativeAnswer: t.boolean,
  generativeTransactionId: et.nullable(t.number),
  embeddedSourcesData: et.nullable(t.array(EmbeddedDataSource)),
})

const GenAIOrigin = t.type({
  kind: t.literal('ai_generated'),
  embeddedSourcesData: t.array(EmbeddedDataSource),
})
const AIAssistedLiveChatOrigin = t.type({
  kind: t.literal('ai_assisted_live_chat'),
  embeddedSourcesData: t.array(EmbeddedDataSource),
})
const UnderstandingNotFoundOrigin = t.type({
  kind: t.literal('understanding_deleted'),
})
const UnderstandingDeletedOrigin = t.type({
  kind: t.literal('understanding_not_found'),
})
export type EmbeddedDataSourceType = t.TypeOf<typeof EmbeddedDataSource>

const ExpandedMessage = t.type({
  id: t.string,
  message: t.string,
  messageTemplate: et.nullable(t.string),
  messageAttributes: AttributeNameMap,
  contactFilter: et.nullable(ContactFilter),
  fuzzyQuestion: et.nullable(t.string),
  matchType: et.nullable(
    t.union([t.literal('fuzzyQuestion'), t.literal('textExpression')])
  ),
  origin: t.union([
    UnderstandingOrigin,
    UnderstandingNotFoundOrigin,
    UnderstandingDeletedOrigin,
    ScriptOrigin,
    GenAIOrigin,
    AIAssistedLiveChatOrigin,
  ]),
  ragtimeInfo: et.nullable(
    t.type({
      generativeTransactionId: t.number,
      embeddedSourcesData: t.array(EmbeddedDataSource),
      generativeTopicName: t.string,
    })
  ),
})

export type ExpandedMessageType = t.TypeOf<typeof ExpandedMessage>

export const conversationsV2ExpandMessage = ({
  messageId,
  genAiTransactionId,
}: {
  readonly messageId: string
  readonly genAiTransactionId: number | undefined
}) =>
  http2({
    url: `/api/v0/conversations-v2/expand_message`,
    method: 'POST',
    data: {
      messageId,
      genAiTransactionId,
      querySource: genAiTransactionId ? 'askAI' : 'conversations',
    },
    shape: ExpandedMessage,
  })

export const conversationsV2FlagMessages = ({
  contactId,
  messageIds,
  flagged,
}: {
  readonly contactId: string
  readonly messageIds: string[]
  readonly flagged: boolean
}) =>
  http2({
    url: `/api/v0/conversations-v2/flag_messages`,
    method: 'PUT',
    data: {
      conversation_id: contactId,
      message_ids: messageIds,
      flagged,
    },
    shape: t.unknown,
  })

export const conversationsV2MarkForKnowledgeReview = ({
  messageId,
  mark,
}: {
  readonly messageId: string
  readonly mark: KnowledgeReviewMark | null
}) =>
  http2({
    url: `/api/v0/conversations-v2/conversation/${messageId}/mark_for_knowledge_review`,
    method: 'PUT',
    data: {
      knowledge_review_mark: mark,
    },
    shape: t.unknown,
  })

export const conversationsV2MarkForKnowledgeReviewBatch = ({
  all,
  selectedIds,
  deselectedIds,
  mark,
}: {
  readonly all: boolean
  readonly selectedIds: string[]
  readonly deselectedIds: string[]
  readonly mark: KnowledgeReviewMark | null
}) => {
  return http2({
    url: `/api/v0/conversations-v2/conversation/mark_for_knowledge_review_batch`,
    method: 'PUT',
    data: {
      all,
      selected_ids: selectedIds,
      deselected_ids: deselectedIds,
      knowledge_review_mark: mark,
    },
    shape: t.unknown,
  })
}

export const getUnderstandingsOverviewCount = () =>
  http2({
    url: `/api/v0/dashboard/understandings_overview_counts`,
    method: 'GET',
    shape: UnderstandingsCount,
  })

export type KnowledgeMatchingCountType = t.TypeOf<typeof KnowledgeMatchingCount>

export type KnowledgeMatchingFilters = {
  channels?: TransportId[]
  timespanDays?: number
  audiences?: number[]
  date_range_expression?: string
}

export const getKnowledgeMatchingCount = (
  data: KnowledgeMatchingFilters = {}
) =>
  http2({
    url: `/api/v0/dashboard/knowledge_matching_count`,
    method: 'POST',
    shape: KnowledgeMatchingCount,
    data,
  })

export const ImportantTopicsCount = t.type({
  topics: t.array(
    t.type({
      total: t.number,
      topic: t.string,
      category: t.string,
    })
  ),
  total: t.number,
})

export type ImportantTopicsCountType = t.TypeOf<typeof ImportantTopicsCount>

export const getImportantTopicsCount = (data: KnowledgeMatchingFilters = {}) =>
  http2({
    url: `/api/v0/dashboard/important_topics_count`,
    method: 'POST',
    shape: ImportantTopicsCount,
    data,
  })

export const getImportantSubtopicsCount = (
  topic: string,
  data: KnowledgeMatchingFilters = {}
) =>
  http2({
    url: `/api/v0/dashboard/important_topics_count/${topic}/`,
    method: 'POST',
    shape: ImportantTopicsCount,
    data,
  })

export const conversationsV2SetReadTimestamp = ({
  contactId,
  timestamp,
  channel,
  markAsUnread = false,
}: {
  readonly contactId: string
  readonly timestamp: string
  readonly channel: string
  readonly markAsUnread?: boolean
}) =>
  http2({
    url: `/api/v0/conversations-v2/conversation/${contactId}/set_read_timestamp/`,
    method: 'POST',
    data: {
      timestamp,
      mark_as_unread: markAsUnread,
      channel,
    },
    shape: t.unknown,
  })

export const conversationsV2MarkAsRead = ({
  all,
  selectedIds,
  deselectedIds,
}: {
  all: boolean
  selectedIds: string[]
  deselectedIds: string[]
}) =>
  http2({
    url: `/api/v0/conversations-v2/conversations/mark_as_read/`,
    method: 'POST',
    data: {
      all,
      selected_ids: selectedIds,
      deselected_ids: deselectedIds,
    },
    shape: t.unknown,
  })

export interface IFetchIdkUnderstandingsResponse {
  id: string
  topic: settings.IDKTopic
  answer?: string
  admithub_answer: string
  answer_count: number
  suggested_answers_count: number
}
export const fetchIdkUnderstandings = () =>
  http.get<Array<IFetchIdkUnderstandingsResponse>>(
    '/api/v0/understandings/idk/all'
  )

export const updateUnderstandingVisiblity = ({
  id,
  visible,
}: {
  id: string
  visible: boolean
}) =>
  http.post<{ visible: boolean; matching: boolean }>(
    `/api/v0/understandings/${id}/set_visibility_for_current_institution/`,
    { visible }
  )

export const listContactSegments = ({
  search,
  region,
}: { readonly search?: string; readonly region?: string | null } = {}) =>
  http.get<IContactSegmentsListResponse>('api/v0/contact-filters/', {
    params: { search, region: region ?? undefined },
  })

export const getContactFilter = (id: number | string) =>
  http.get<IContactFilterResponseData>(`api/v0/contact-filters/${id}/`)

const ContactConditionGroupType: t.Type<IContactConditionGroupResponseData> = t.recursion(
  'ContactConditionGroupType',
  () => {
    return t.type({
      combination_type: fromEnum('CombinationOperator', CombinationOperator),
      conditions: t.array(
        t.type({
          comparison_type: t.union([
            fromEnum('ComparisonOperator', ComparisonOperator),
            t.null,
          ]),
          contact_attribute: t.union([
            t.type({
              id: t.number,
              institution: t.number,
              name: t.string,
              requires_auth: t.boolean,
              include_in_escalations: t.boolean,
              data_type: fromEnum('ContactAttributeType', ContactAttributeType),
              multi_choices: t.array(
                t.type({
                  id: t.number,
                  value: t.union([t.string, t.null]),
                  order: t.union([t.number, t.null]),
                })
              ),
            }),
            t.null,
          ]),
          contact_field: t.union([t.string, t.null]),
          value: t.union([t.string, t.null]),
        })
      ),
      condition_groups: t.array(ContactConditionGroupType),
    })
  }
)

export const getHistoricalContactFilter = (
  id: string,
  isRecurring: boolean,
  isDataTriggered?: boolean
) => {
  const baseRoute = getCampaignRequestPath({ isRecurring, isDataTriggered })
  return http2({
    url: `api/v0/${baseRoute}/${id}/historical_contact_filter/`,
    method: 'GET',
    params: {
      isDataTriggered,
    },
    shape: t.type({
      deleted: t.boolean,
      contact_filter: t.union([
        t.type({
          id: t.number,
          name: t.string,
          condition_group: ContactConditionGroupType,
        }),
        t.null,
      ]),
    }),
  })
}

export const getCampaignEngagementData = (id: string, isRecurring: boolean) => {
  const baseRoute = isRecurring ? 'recurring-campaigns' : 'campaigns'
  return http2({
    url: `api/v0/${baseRoute}/${id}/campaign_engagement/`,
    method: 'GET',
    shape: t.type({
      engagement_data: t.type({
        opt_outs: t.number,
        pauses: t.number,
        count_campaigns_sent: t.union([t.number, t.null]),
        total_contacts: t.number,
      }),
    }),
  })
}

export const updateOrCreateContactFilter = (data: IContactFilterRequestData) =>
  data.id
    ? http.put<IContactFilterResponseData>(
        `/api/v0/contact-filters/${data.id}/`,
        data
      )
    : http.post<IContactFilterResponseData>('/api/v0/contact-filters/', data)

export const getInstitutionContactAuthSettings = () =>
  http2({
    url: '/api/v0/contact-auth/get_settings_page_data/',
    method: 'GET',
    shape: t.type({
      all_emails: t.array(t.string),
      auth_settings: t.type({
        enabled: t.boolean,
        web_bot_immediate_auth: t.boolean,
        email_field_name: t.union([t.string, t.null]),
        minutes_until_primary_contact_auth_success_expires: t.number,
        minutes_until_primary_contact_auth_failure_expires: t.number,
        minutes_until_secondary_contact_auth_success_expires: t.number,
        minutes_until_secondary_contact_auth_failure_expires: t.number,
        minutes_until_code_expires: t.number,
      }),
    }),
  })

export const getDialogTopics = () =>
  http2({
    url: '/api/v0/dialogs/topics/',
    method: 'GET',
    shape: t.array(t.string),
  })

export const updateInstitutionContactAuthSettings = ({
  enabled,
  webBotImmediateAuth,
  email,
  primarySuccessDuration,
  primaryFailureDuration,
  secondarySuccessDuration,
  secondaryFailureDuration,
  codeDuration,
}: IInstitutionContactAuthSettingsRequestData) =>
  http2({
    data: {
      enabled,
      webBotImmediateAuth,
      email,
      primarySuccessDuration,
      primaryFailureDuration,
      secondarySuccessDuration,
      secondaryFailureDuration,
      codeDuration,
    },
    url: '/api/v0/contact-auth/create_or_update/',
    method: 'POST',
    shape: t.type({
      enabled: t.boolean,
      web_bot_immediate_auth: t.boolean,
      email_field_name: t.union([t.string, t.null]),
      minutes_until_primary_contact_auth_success_expires: t.number,
      minutes_until_primary_contact_auth_failure_expires: t.number,
      minutes_until_secondary_contact_auth_success_expires: t.number,
      minutes_until_secondary_contact_auth_failure_expires: t.number,
      minutes_until_code_expires: t.number,
    }),
  })

export const commandsApi = {
  list: () => http.get<HashtagCommand[]>('/api/v0/commands/list/'),
  retrieve: (arg: { id: number }) =>
    http.get<HashtagCommand>(`/api/v0/commands/${arg.id}/`),
  partialUpdate: (arg: HashtagPartialUpdateRequest) =>
    http.patch<HashtagCommand>(`/api/v0/commands/${arg.id}/update/`, arg),
  update: (arg: HashtagCommandUpdateRequest) =>
    http.patch<HashtagCommand>(`/api/v0/commands/${arg.id}/update/`, arg),
  create: (arg: HashtagCommandCreateRequest) =>
    http.post<HashtagCommand>(`/api/v0/commands/create/`, arg),
  delete: (arg: { id: number }) =>
    http.delete(`/api/v0/commands/${arg.id}/delete/`),
}

export const conversationsV2GetFilterLabels = (data: {
  readonly campaignId?: string
  readonly scriptId?: string
  readonly audienceIds?: readonly string[]
}) =>
  http2({
    url: '/api/v0/conversations-v2/get_filter_labels',
    method: 'POST',
    shape: t.type({
      campaign: et.nullable(
        t.type({
          id: t.string,
          name: t.string,
        })
      ),
      script: et.nullable(
        t.type({
          id: t.string,
          name: t.string,
        })
      ),
      audiences: withFallback(
        t.array(
          t.type({
            id: t.string,
            name: t.string,
          })
        ),
        []
      ),
    }),
    data,
  })

const AudienceFoldersChoices = t.type({
  audiences: t.array(
    t.type({
      id: t.number,
      name: t.string,
    })
  ),
})

export const conversationsV2AudienceFoldersGetChoices = ({
  selected,
}: {
  readonly selected?: boolean
}) =>
  http2({
    url: '/api/v0/conversations-v2/audience_folders/choices',
    method: 'GET',
    params: { selected },
    shape: AudienceFoldersChoices,
  })

export const conversationsV2AudienceFoldersSelect = ({
  audienceId,
  selected,
}: {
  readonly audienceId: number
  readonly selected?: boolean
}) =>
  http2({
    url: `/api/v0/conversations-v2/audience_folders/audience/${audienceId}/select`,
    method: 'POST',
    data: { selected },
    shape: t.unknown, // no content
  })

export const AudienceFolderCount = t.type({
  count: t.number,
})

export const conversationsV2AudienceFoldersCount = ({
  audienceId,
}: {
  readonly audienceId: number
}) =>
  http2({
    url: `/api/v0/conversations-v2/audience_folders/audience/${audienceId}/count`,
    method: 'GET',
    shape: AudienceFolderCount,
  })

export const listContactLabelNames = ({
  search,
  cancelToken,
  limit = 50,
}: Omit<IListLabelNames, 'custom_only'>) =>
  http2({
    url: `/api/v0/contact-labels/list_all_contact_labels_names/`,
    method: 'GET',
    params: {
      search,
      limit,
    },
    cancelToken,
    shape: t.type({
      labels: t.array(t.string),
    }),
  })

export const listWebbotTokensAndTitles = ({
  search,
  cancelToken,
}: {
  search: string
  cancelToken: CancelToken
}) =>
  http2({
    url: `/api/v0/settings/list_webchat_tokens_and_titles/`,
    method: 'GET',
    params: { search },
    cancelToken,
    shape: t.record(t.string, t.string),
  })

export type KnowledgeBaseReviewItemsParams = {
  readonly page: number
  readonly limit: number
  readonly reviewed?: number
  readonly search?: string
  readonly transports?: readonly string[]
  readonly timespanDays?: number
  readonly reason?: KnowledgeReviewReason
  readonly audience?: readonly string[]
}

export enum KnowledgeReviewAnswerType {
  HistoricalAnswer = 'historicalAnswer',
  UserResponse = 'userResponse',
  MainstayDefaultScript = 'mainstayDefaultScript',
  Default = 'default',
  Fallback = 'fallback',
  FlashResponse = 'flashResponse',
}

const KnowledgeBaseReviewItemShape = t.type({
  message: t.type({
    id: t.string,
    body: t.string,
    contact: t.type({ id: t.string, name: et.nullable(et.string) }),
  }),
  reason: et.nullable(fromEnum('KnowledgeReviewReason', KnowledgeReviewReason)),
  note: et.nullable(et.string),
  understanding: et.nullable(et.string),
  understandingId: withFallback(et.nullable(et.string), null),
  answer: et.nullable(et.string),
  createdAt: et.string,
  reviewed: et.boolean,
  transport: et.nullable(fromEnum('TransportEnum', TransportEnum)),
  answerId: withFallback(et.nullable(et.number), null),
  matchedQuestion: et.nullable(et.string),
  dialogId: et.nullable(et.string),
  answerType: fromEnum('KnowledgeReviewAnswerType', KnowledgeReviewAnswerType),
  incomingMessageContexts: et.nullable(
    t.array(
      t.type({
        artifact: t.union([t.literal('dialog'), t.literal('campaign')]),
        id: et.nullable(t.string), // we return an id of null for internal scripts
        name: t.string,
      })
    )
  ),
})

export type KnowledgeBaseReviewItemShapeType = t.TypeOf<
  typeof KnowledgeBaseReviewItemShape
>

export const knowledgeBaseReviewItems = ({
  params,
  cancelToken,
}: {
  readonly params: KnowledgeBaseReviewItemsParams
  readonly cancelToken: CancelToken
}) =>
  http2({
    url: '/api/v0/knowledge_base/review_items',
    method: 'GET',
    params,
    cancelToken,
    shape: t.type({
      review_items: t.array(KnowledgeBaseReviewItemShape),
      total_count: t.number,
    }),
  })

export type ContactFeedbackParams = {
  readonly page: number
  readonly limit: number
}
export type ContactFeedbackShapeType = t.TypeOf<typeof ContactFeedbackShape>

export const ContactFeedbackShape = t.type({
  id: et.string,
  rating: et.string,
  message: et.string,
  botResponse: et.nullable(et.string),
  comment: et.nullable(et.string),
  createdAt: et.string,
  contact: et.nullable(t.type({ id: et.string, name: et.nullable(et.string) })),
  messageId: et.string,
  transport: et.nullable(fromEnum('TransportEnum', TransportEnum)),
  note: et.nullable(et.string),
})
export const contactFeedback = ({
  params,
  cancelToken,
}: {
  readonly params: ContactFeedbackParams
  readonly cancelToken: CancelToken
}) =>
  http2({
    url: '/api/v0/knowledge_base/contact_feedback',
    method: 'GET',
    params,
    cancelToken,
    shape: t.type({
      contact_feedback: t.array(ContactFeedbackShape),
      total_count: t.number,
    }),
  })

export const contactFeedbackStatistics = (params: {
  contact_filter: number | null | undefined
  date_range_expression: string
  transports: TransportId[]
}) =>
  http2({
    url: '/api/v0/knowledge_base/contact_feedback/statistics',
    method: 'GET',
    params: { ...params, contact_filter: params.contact_filter ?? undefined },
    shape: WebChatFeedBackStatisticsShape,
  })

export const downloadContactFeedbackReport = ({
  preferences,
  startDate,
  endDate,
}: {
  preferences?: IPreferencesRequestPayload
  startDate: null | Date
  endDate: null | Date
}) =>
  http2({
    url: '/api/v0/knowledge_base/download_contact_feedback',
    method: 'POST',
    data: { preferences, startDate, endDate },
    shape: t.type({ taskId: t.string }),
  })

const PollDownloadType = t.type({
  status: t.string,
  report_url: et.nullable(t.string),
})

export const pollDownloadContactFeedbackReport = (taskId: string) =>
  http2({
    url: '/api/v0/knowledge_base/poll_download_contact_feedback',
    method: 'GET',
    params: { task_id: taskId },
    shape: PollDownloadType,
  })

export const knowledgeBaseReviewItemsCount = () =>
  http2({
    url: '/api/v0/knowledge_base/review_items_count',
    method: 'GET',
    shape: ReviewItemsShape,
  })

export const knowledgeBaseItemMarkAsReviewed = ({
  id,
  reviewed,
}: {
  readonly id: string
  readonly reviewed: boolean
}) =>
  http2({
    url: `/api/v0/knowledge_base/review_items/${id}/mark_as_reviewed`,
    method: 'PUT',
    data: {
      reviewed,
    },
    shape: t.unknown,
  })

export const knowledgeBaseItemsMarkAsReviewed = ({
  all,
  selectedIds,
  deselectedIds,
}: {
  readonly all: boolean
  readonly selectedIds: string[]
  readonly deselectedIds: string[]
}) =>
  http2({
    url: `/api/v0/knowledge_base/review_items/mark_as_reviewed`,
    method: 'PUT',
    data: {
      all,
      selected_ids: selectedIds,
      deselected_ids: deselectedIds,
    },
    shape: t.unknown,
  })

export const listUnderstandingsForInstitution = (
  query: string,
  cancelToken: CancelToken,
  limit: number = 50
) =>
  http2({
    url: `/api/v0/escalation-rules/settings/list_institution_understandings/`,
    method: 'GET',
    params: {
      query,
      limit,
    },
    cancelToken,
    shape: t.type({
      results: t.array(
        t.type({
          mongoId: t.string,
          topic: t.string,
          sampleQuestion: t.string,
        })
      ),
    }),
  })

export const renameAudience = (newName: string, id: number) =>
  http2({
    url: `/api/v0/contact-filters/${id}/rename/`,
    method: 'PATCH',
    data: {
      new_name: newName,
    },
    shape: t.type({
      name: t.string,
      detail: t.string,
    }),
  })

export const duplicateAudience = (newName: string, id: number) =>
  http2({
    url: `/api/v0/contact-filters/${id}/duplicate/`,
    method: 'POST',
    data: {
      new_name: newName,
    },
    shape: t.type({
      id: t.number,
      detail: t.string,
    }),
  })

export const listAudience = (
  search?: string,
  limit?: number,
  offset?: number,
  sort?: string,
  order?: 'asc' | 'desc'
) =>
  http2({
    url: `/api/v0/contact-filters/get_detailed_audience_list/`,
    method: 'GET',
    params: { search, limit, offset, sort_type: sort, sort_dir: order },
    shape: t.type({
      count: t.number,
      results: t.array(
        t.type({
          id: t.number,
          name: t.string,
          answer_response_count: t.number,
          all_campaign_count: t.number,
          size: t.union([t.number, t.null]),
        })
      ),
    }),
  })
export const listContactAttributes = (
  search?: string,
  limit?: number,
  offset?: number,
  sort?: string,
  order?: 'asc' | 'desc'
) =>
  http2({
    url: `/api/v0/contact-attributes/get_sortable_list/`,
    method: 'GET',
    params: { search, limit, offset, sort_type: sort, sort_dir: order },
    shape: t.type({
      count: t.number,
      results: t.array(
        t.type({
          id: t.number,
          name: t.string,
          description: t.union([t.string, t.null]),
          data_type: t.string,
          requires_auth: t.boolean,
          include_in_escalations: t.boolean,
          locations_used: t.number,
        })
      ),
    }),
  })

export const listPersonalizedResponses = (
  contactFilter: number,
  limit?: number,
  offset?: number,
  sort?: string,
  order?: 'asc' | 'desc'
) =>
  http2({
    url: `/api/v0/contact-filters/get_personalized_responses/`,
    method: 'GET',
    params: {
      limit,
      offset,
      sort_type: sort || 'topic',
      sort_dir: order || 'asc',
      contact_filter: contactFilter,
    },
    shape: t.type({
      count: t.number,
      results: t.array(
        t.type({
          id: t.number,
          category: t.string,
          sub_category: t.string,
          sample_question: t.string,
          answer: t.union([t.null, t.string]),
          dialog_mongo_id: t.union([t.null, t.string]),
          dialog_name: t.union([t.null, t.string]),
          approval_status: t.boolean,
          sent_count: t.number,
          understanding_id: t.string,
        })
      ),
    }),
  })

export const listAudienceCampaigns = (
  contactFilter: number,
  page?: number,
  pageSize?: number,
  sort?: string,
  order?: 'asc' | 'desc'
) =>
  http2({
    url: 'api/v0/contact-filters/get_all_campaigns/',
    method: 'GET',
    params: {
      contact_filter: contactFilter,
      page,
      page_size: pageSize,
      sort_type: sort,
      sort_dir: order,
    },
    shape: t.type({
      count: t.number,
      results: t.array(
        t.type({
          _id: t.string,
          date: t.union([t.string, t.null]),
          name: t.string,
          description: t.string,
          isInteractive: t.boolean,
          size: t.number,
          engagement: et.nullable(t.number),
          started: t.union([t.boolean, t.null]),
          recurring: t.union([t.boolean, t.null]),
          isDataTriggered: t.union([t.boolean, t.null]),
        })
      ),
    }),
  })

export const getAudienceCacheStatus = (id: number, refresh?: boolean) =>
  http2({
    url: `/api/v0/contact-filters/${id}/get_cache_status/`,
    method: 'GET',
    params: {
      refresh: String(!!refresh),
    },
    shape: t.type({
      state: t.string,
      last_calculated: t.string,
    }),
  })

export const getContactFilterReferences = (id: string) =>
  http2({
    url: `/api/v0/contact-filters/${id}/get_all_references/`,
    method: 'GET',
    shape: t.array(
      t.type({
        id: t.string,
        type: withFallback(
          t.union([
            t.literal('understanding'),
            t.literal('escalation_rule'),
            t.literal('sent_campaign'),
            t.literal('upcoming_campaign'),
            t.literal('custom_dashboard'),
            t.literal('unknown'),
          ]),
          'unknown'
        ),
        name: t.union([t.string, t.null]),
      })
    ),
  })

export type KBTopicsShapeType = t.TypeOf<typeof KBTopicsShape>

const KBExistingTopicsShape = t.record(t.string, t.array(t.string))
const KBStandardTopicsShape = t.array(t.string)

export const KBTopicsShape = t.type({
  existingTopics: KBExistingTopicsShape,
  standardTopics: KBStandardTopicsShape,
})

export const getExistingKBTopics = () =>
  http2({
    url: `/api/v0/knowledge_base/topics`,
    method: 'GET',
    shape: KBExistingTopicsShape,
  })

export const getStandardKBTopics = () =>
  http2({
    url: `/api/v0/knowledge_base/standard_topics`,
    method: 'GET',
    shape: KBStandardTopicsShape,
  })

export const downloadKB = (
  institutionId: string | undefined,
  includeFuzzyQuestions: boolean
) =>
  http2({
    url: `/api/v0/knowledge_base/download`,
    method: 'GET',
    params: {
      institution_id: institutionId,
      include_fuzzy_questions: includeFuzzyQuestions,
    },
    shape: t.type({
      task_id: t.string,
    }),
  })

export const pollDownloadKB = (taskId: string) =>
  http2({
    url: `/api/v0/knowledge_base/poll_download`,
    method: 'GET',
    params: { task_id: taskId },
    shape: PollDownloadType,
  })

export const getRoles = () =>
  http2({
    url: `/api/v0/roles/`,
    method: 'GET',
    shape: t.array(
      t.type({
        name: t.string,
        institution: et.nullable(t.number),
        id: t.number,
      })
    ),
  })

const UserProfile = t.type({
  name: t.string,
  firstName: t.union([t.string, t.undefined]),
  lastName: t.union([t.string, t.undefined]),
})

const UserShape = t.type({
  id: t.string,
  emails: et.array(
    t.type({
      address: t.string,
      verified: t.union([t.boolean, t.undefined]),
    })
  ),
  admitHubUser: t.boolean,
  engineeringEmployee: t.boolean,
  currentInstitution: t.union([t.string, t.undefined]),
  profile: UserProfile,
  roles: t.undefined,
  userGroups: t.union([et.array(t.string), t.null]),
  lastLoginAt: t.union([t.string, t.undefined, t.null]),
  permissionRoles: et.array(t.string),
})

export const updateUserRole = (
  userId: string,
  roleName: string,
  userGroups: string[] | null
) =>
  http2({
    url: `/api/v0/users/change_role/`,
    method: 'PATCH',
    data: {
      userId,
      roleName,
      userGroups,
    },
    shape: UserShape,
  })

export const updateUserEngineeringEmployee = (
  userId: string,
  engineeringEmployee: boolean
) =>
  http2({
    url: `/api/v0/users/change_engineering_employee/`,
    method: 'PATCH',
    data: {
      userId,
      engineeringEmployee,
    },
    shape: UserShape,
  })

export const deleteAudienceRule = (id: number) =>
  http2({
    url: `/api/v0/contact-filters/${id}`,
    method: 'DELETE',
    shape: t.type({
      task_id: t.string,
    }),
  })

export const pollDeleteAudienceRule = (taskId: string) =>
  http2({
    url: `/api/v0/contact-filters/poll/?task_id=${taskId}`,
    method: 'GET',
    shape: t.type({
      task_state: t.string,
      detail: t.string,
      result: et.nullable(t.string),
    }),
  })

export const loginSSO = (email: string) =>
  http2({
    url: '/api/v0/auth/login/sso/init',
    method: 'POST',
    data: {
      email,
    },
    shape: t.type({
      redirect: t.string,
    }),
  })

export const createWebhookSubscription = (url: string, name: string) =>
  http2({
    url: '/api/v0/webhooks/subscriptions/',
    method: 'POST',
    data: {
      endpoint_url: url,
      name,
    },
    shape: t.type({
      shared_secret: t.string,
      name: t.string,
      endpoint_url: t.string,
      subscription_id: t.string,
    }),
  })

export const listWebhookSubscriptions = () =>
  http2({
    url: '/api/v0/webhooks/subscriptions/',
    method: 'GET',
    shape: t.array(
      t.type({
        subscription_id: t.string,
        name: t.string,
        endpoint_url: t.string,
        enabled: t.boolean,
      })
    ),
  })

export const updateWebhook = (id: string, eventType: string) =>
  http2({
    url: `/api/v0/webhooks/subscriptions/${id}/`,
    method: 'PATCH',
    shape: t.type({ subscription_id: t.string }),
    data: {
      event_type: eventType,
    },
  })

export const revokeWebhook = (id: string) =>
  http2({
    url: `/api/v0/webhooks/subscriptions/${id}/`,
    method: 'DELETE',
    shape: t.unknown,
  })

export const loginSSOVerify = () =>
  http2({
    url: '/api/v0/auth/login/sso/verify',
    method: 'GET',
    shape: t.type({
      user: UserShape,
      token: t.union([t.string, t.null]),
    }),
  })

interface IUpdateSSORequestData {
  readonly id?: string
  readonly ssoEntityID: string
  readonly ssoDomain: string
  readonly passwordBasedAuthDisabled: boolean
  readonly enableSSO: boolean
}
export const updateSettingsSSO = ({
  id = settings.CURRENT_INSTITUTION_LOOKUP_ID,
  ssoEntityID,
  ssoDomain,
  passwordBasedAuthDisabled,
  enableSSO,
}: IUpdateSSORequestData) =>
  http2({
    url: `/api/v0/institution/${id}/update_sso_settings/`,
    method: 'POST',
    data: {
      ssoEntityID,
      ssoDomain,
      passwordBasedAuthDisabled,
      enableSSO,
    },
    shape: t.unknown,
  })

export const getInsightsDataTypes = <C extends t.Mixed>(codec: C) => {
  return t.type({
    bucket: t.number,
    bucket_datetime: t.string,
    points: codec,
  })
}

export const generateInsightSeriesType = <C extends t.Mixed>(
  data: IInsightsRequestData,
  codec: C
) => {
  return data.series.map(() => {
    return t.type({
      label: et.nullable(t.string),
      insight: withFallback(
        t.union([
          t.literal('campaigns'),
          t.literal('conversations'),
          t.literal('engagement'),
          t.literal('incoming_message'),
          t.literal('individuals_contacted'),
          t.literal('opt_out'),
          t.literal('important_topics'),
          t.literal('missed_questions'),
          t.literal('answered_questions'),
          t.literal('webchat_feedback'),
          t.literal('unknown'),
        ]),
        'unknown'
      ),
      data: t.array(getInsightsDataTypes(codec)),
    })
  })
}

export const getInsights = <C extends t.Mixed>(
  data: IInsightsRequestData,
  codec: C
): Promise<Either<Http2ErrorUnion, t.TypeOf<typeof codec>>> => {
  return http2({
    url: '/api/v0/insights/',
    method: 'POST',
    data,
    // workaround for issues with mixed arrays
    // we'll assume the response is an array of 1
    shape: t.array(generateInsightSeriesType(data, codec)[0]),
  })
}

export const getInsightsReportTask = (params: IInsightsReportRequestData) => {
  return http2({
    url: '/api/v0/insights/report',
    method: 'GET',
    params,
    shape: t.type({ task_id: t.string }),
  })
}

export const generateInsightsReport = (
  data: IInsightsReportPostRequestData
) => {
  return http2({
    url: '/api/v0/insights/generate_report',
    method: 'POST',
    data,
    shape: t.type({ task_id: t.string }),
  })
}

export const pollInsightsReport = (taskId: string) => {
  return http2({
    url: '/api/v0/insights/poll',
    method: 'GET',
    params: { task_id: taskId },
    shape: t.type({
      status: t.union([t.undefined, t.string]),
      url: t.union([t.undefined, t.string]),
    }),
  })
}

export const listWebhookSubscriptionNames = () =>
  http2({
    url: '/api/v0/webhooks/get_names/',
    method: 'GET',
    shape: t.type({ names: t.array(t.string) }),
  })

export const forwardToWebhook = (messageId: string) =>
  http2({
    url: '/api/v0/conversations/forward_to_webhook/',
    method: 'POST',
    shape: t.unknown,
    data: { messageId },
  })

interface IGetReportPreferences {
  insight: string
  isRecurring?: boolean
  isDataTriggered?: boolean
  isHelloPage?: boolean
  isWebBot?: boolean
  id?: string
  dialogId?: string
  contactsParams?: {
    searchQuery: string
    filters: Params
    contactFilterId?: number | string | null
  }
}
export const getReportPreferences = ({
  insight,
  isRecurring = false,
  isDataTriggered = false,
  isHelloPage,
  isWebBot,
  id,
  dialogId,
  contactsParams,
}: IGetReportPreferences) => {
  const { searchQuery, filters, contactFilterId } = contactsParams ?? {}
  const campaignParam = getCampaignIdParams({
    isRecurring,
    isDataTriggered,
    isHelloPage,
    isWebBot,
    id,
  })
  return http2({
    url: '/api/v0/insights/get_fields_preferences',
    method: 'GET',
    params: {
      insight,
      dialogId,
      ...campaignParam,
      searchQuery,
      contact_filter: contactFilterId || undefined,
      ...filters,
    },
    shape: t.type({
      include_empty_custom_fields: t.boolean,
      top_level_fields: t.array(
        t.type({
          checked: t.boolean,
          id: t.string,
          readonly: t.boolean,
          name: t.string,
        })
      ),
      custom_fields: t.array(
        t.type({ checked: t.boolean, id: t.number, name: t.string })
      ),
      report_specific_fields: t.array(
        t.type({ checked: t.boolean, name: t.string })
      ),
      empty_custom_fields: t.array(t.number),
      recurring_campaigns_to_include: t.union([t.null, t.number]),
    }),
  })
}

export const getOpenTextResponses = ({
  stateId,
  id,
  latest,
  isRecurring,
  isAggregate,
  isDataTriggered,
  isHelloPage,
  isWebBot,
  audience,
  dialogId,
}: {
  stateId: string
  id: string
  latest: string
  isRecurring: boolean
  isAggregate: boolean
  isDataTriggered?: boolean
  dialogId?: string
  isHelloPage?: boolean
  isWebBot?: boolean
  audience?: string
}) => {
  let path = 'get_prompt_responses/'
  const campaignParam = getCampaignIdParams({
    isRecurring,
    isDataTriggered,
    id,
  })
  if (isDataTriggered || isHelloPage || isWebBot) {
    const prefix = getCampaignRequestPath({
      isDataTriggered,
      isHelloPage,
      isWebBot,
    })
    path = `${prefix}/${id}/get_prompt_responses/`
  }
  return http2({
    url: `/api/v0/${path}`,
    method: 'GET',
    params: {
      stateId,
      latest,
      isAggregate,
      audience,
      ...campaignParam,
      ...(dialogId && { dialogId }),
    },
    shape: t.array(
      t.type({
        body: t.string,
        name: t.union([t.null, t.string]),
        dateTime: t.string,
        smsLogId: t.union([t.string, t.undefined]),
        contactId: t.string,
        transport: fromEnum('TransportEnum', TransportEnum),
      })
    ),
  })
}
export const getAudienceNames = () =>
  http2({
    url: `/api/v0/contact-filters/get_all_names/`,
    method: 'GET',
    shape: t.type({
      audience_names: t.array(t.string),
    }),
  })

export const createAudienceFromResponses = ({
  campaignId,
  audienceName,
  contactLabelText,
  workflowResponses,
  isRecurring,
  isDataTriggered,
  isAggregate,
  dialogId,
  isHelloPage,
  isWebBot,
  audience,
  region,
}: ICreateAudienceFromSurveyResponses) => {
  const path = getCampaignRequestPath({
    isRecurring,
    isDataTriggered,
    isHelloPage,
    isWebBot,
  })
  return http2({
    url: `/api/v0/${path}/${campaignId}/create_audience_from_responses/`,
    method: 'POST',
    data: {
      audienceName,
      contactLabelText,
      workflowResponses,
      isAggregate,
      isHelloPage,
      isWebBot,
      audience,
      ...(dialogId && { dialogId }),
      region,
    },
    shape: t.type({
      audience_id: t.number,
    }),
  })
}

interface IAddRegionToInstitutionRequestData {
  readonly id?: string
  readonly regionValue: string
  readonly action: 'add' | 'remove'
}

export const updateAvailableRegion = ({
  id = settings.CURRENT_INSTITUTION_LOOKUP_ID,
  regionValue,
  action,
}: IAddRegionToInstitutionRequestData) =>
  http2({
    url: `/api/v0/institution/${id}/update_available_regions/`,
    method: 'POST',
    data: { regionValue, action },
    shape: t.type({
      availableRegions: t.array(t.string),
    }),
  })

export const setRegionAttribute = ({ id }: { id: number | undefined }) =>
  http2({
    url: 'api/v0/contact-attributes/set_region_attribute/',
    method: 'POST',
    data: { attributeId: id },
    shape: t.unknown,
  })

export const editUserProfile = (data: {
  firstName: string
  lastName: string
}) =>
  http2({
    url: `/api/v0/users/edit_profile/`,
    method: 'PATCH',
    data,
    shape: t.type({
      profile: UserProfile,
    }),
  })

const GenerativeTextPromptShape = t.type({
  id: t.number,
  name: t.string,
})
export type GenerativeTextPromptShapeType = t.TypeOf<
  typeof GenerativeTextPromptShape
>

const ToolCallFeaturesShape = t.type({
  [FeaturesType.ANSWERS_GENERATIVE_PARTNER_SETTING]: t.boolean,
  [FeaturesType.GENERATIVE_AI_CONVERSATION_SUMMARY]: t.boolean,
  [FeaturesType.ESCALATION_EMAIL_SUGGESTED_REPLY]: t.boolean,
  [FeaturesType.GENERATIVE_AI_FALLBACK_RESPONSES]: t.boolean,
  [FeaturesType.GENERATIVE_AI_FUZZY_QUESTIONS]: t.boolean,
  [FeaturesType.GENERATIVE_AI_KNOWLEDGE_BASE_ANSWERS]: t.boolean,
  [FeaturesType.GENERATIVE_AI_LIVE_CHAT_SUGGESTIONS]: t.boolean,
  [FeaturesType.GENERATIVE_AI_PARTNER_FACING_SCRAPER]: t.boolean,
  [FeaturesType.RAGTIME]: t.boolean,
})

export type ToolCallFeaturesShapeType = t.TypeOf<typeof ToolCallFeaturesShape>

const GenerativeAISettingsShape = t.type({
  enablePIIAnonymization: t.boolean,
  matchingThreshold: t.number,
  bedrockContentMatchingThreshold: t.number,
  messageHistoryMessageCount: t.number,
  messageHistoryTimeWindow: t.number,
  specialInstructions: t.string,
  specialInstructionsOverride: t.boolean,
  conversationSummaryPrompt: t.string,
  suggestedReplyPrompt: t.string,
  suggestedReplyPromptSensitive: t.string,
  fuzzyQuestionGenerationPrompt: t.string,
  useToolCall: t.boolean,
  genaiModel: t.type({
    value: t.string,
    options: t.array(t.string),
  }),
  toolCallFeatures: ToolCallFeaturesShape,
})
export type GenerativeAISettingsShapeType = t.TypeOf<
  typeof GenerativeAISettingsShape
>
const InstitutionGenerativeAISettingsShape = t.type({
  generative_ai_settings: GenerativeAISettingsShape,
})

export type InstitutionGenerativeAISettingsShapeType = t.TypeOf<
  typeof InstitutionGenerativeAISettingsShape
>

const GenerativeTextServiceTransactionLiveChatUsageShape = t.union([
  t.null,
  t.type({
    selected_customize: t.union([t.null, t.boolean]),
    used_for_response_sms_log_id: t.union([t.null, t.string]),
    response_info: t.union([
      t.null,
      t.type({
        user_id: t.string,
        transport: transportSchema,
        body: t.string,
      }),
    ]),
  }),
])

const GenerativeTextServiceTransactionShape = t.type({
  id: t.number,
  generative_text_model: t.string,
  request_datetime: t.string,
  response_datetime: t.string,
  prompt: t.string,
  response: t.string,
  action: t.string,
  user_query: t.union([t.null, t.string]),
  generate_from_chat: t.union([
    t.null,
    t.type({
      sms_logs: t.union([
        t.null,
        t.type({
          in_response_to_sms_log_id: t.union([t.string, t.null]),
          info: t.union([
            t.type({
              user_id: t.string,
              transport: transportSchema,
              body: t.string,
            }),
            t.null,
          ]),
        }),
      ]),
      live_chat_usage: GenerativeTextServiceTransactionLiveChatUsageShape,
    }),
  ]),
  fuzzy_question_data: t.union([
    t.null,
    t.type({ sample_question: t.string, understanding_mongo_id: t.string }),
  ]),
  kb_response_data: t.union([
    t.null,
    t.type({
      summary_question: t.string,
      understanding_mongo_id: t.string,
      kb_response_usage: t.union([
        t.literal('dismissed'),
        t.literal('selected'),
        t.literal('ignored'),
      ]),
    }),
  ]),
  embedded_sources_data: et.nullable(t.array(EmbeddedDataSource)),
  external_response_id: et.nullable(t.string),
})
export type GenerativeTextServiceTransactionShapeType = t.TypeOf<
  typeof GenerativeTextServiceTransactionShape
>

export const enum GenerativeTextServiceTransactionAction {
  GenerateFromChat = 'generate_from_chat',
}

export const enum GenAIScapeKnowledgeSourceType {
  URL = 'URL',
  DOC = 'DOC',
  PDF = 'PDF',
  WEBSITE = 'WEBSITE',
  S3_DOC = 'S3_DOC',
}

const GenAIScapeKnowledgeSourceTypeShape = t.union([
  t.literal(GenAIScapeKnowledgeSourceType.URL),
  t.literal(GenAIScapeKnowledgeSourceType.DOC),
  t.literal(GenAIScapeKnowledgeSourceType.WEBSITE),
  t.literal(GenAIScapeKnowledgeSourceType.S3_DOC),
])

const GenAIScrapeKnowledgeSourceShape = t.type({
  id: t.number,
  title: et.nullable(t.string),
  description: t.union([t.string, t.null]),
  source_type: GenAIScapeKnowledgeSourceTypeShape,
  origin_url: et.nullable(t.string),
  s3_uri: et.nullable(t.string),
  original_filename: et.nullable(t.string),
  scrape_date: t.union([t.null, t.string]),
  latest_scrape_status: t.union([t.null, t.string]),
  fail_reason: t.union([t.null, t.string]),
})
export type GenAIScrapeKnowledgeSourceShapeType = t.TypeOf<
  typeof GenAIScrapeKnowledgeSourceShape
>

const BulkScrapeKnowledgeSourceShape = t.array(GenAIScrapeKnowledgeSourceShape)

const GenAIScrapeKnowledgeSourceCreateReqShape = t.type({
  source_type: t.string,
  origin_url: t.string,
})
export type GenAIScrapeKnowledgeSourceCreateReqShapeType = t.TypeOf<
  typeof GenAIScrapeKnowledgeSourceCreateReqShape
>

export const enum GenAIScrapeAttemptStatus {
  QUEUED = 'QUEUED',
  STARTED = 'STARTED',
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
  PARTIAL_FAIL = 'PARTIAL_FAIL',
  DELETE_QUEUED = 'DELETE_QUEUED',
  DELETE_STARTED = 'DELETE_STARTED',
  DELETE_SUCCESS = 'DELETE_SUCCESS',
  DELETE_FAIL = 'DELETE_FAIL',
}

const GenAIScrapeAttemptStatusShape = t.union([
  t.literal(GenAIScrapeAttemptStatus.QUEUED),
  t.literal(GenAIScrapeAttemptStatus.STARTED),
  t.literal(GenAIScrapeAttemptStatus.SUCCESS),
  t.literal(GenAIScrapeAttemptStatus.FAIL),
  t.literal(GenAIScrapeAttemptStatus.PARTIAL_FAIL),
  t.literal(GenAIScrapeAttemptStatus.DELETE_QUEUED),
  t.literal(GenAIScrapeAttemptStatus.DELETE_STARTED),
  t.literal(GenAIScrapeAttemptStatus.DELETE_SUCCESS),
  t.literal(GenAIScrapeAttemptStatus.DELETE_FAIL),
])

const GenAIScrapeAttemptShape = t.type({
  id: t.number,
  created: t.string,
  modified: t.string,
  knowledge_source: GenAIScrapeKnowledgeSourceShape,
  status: GenAIScrapeAttemptStatusShape,
  status_message: t.union([t.string, t.null]),
})
export type GenAIScrapeAttemptShapeType = t.TypeOf<
  typeof GenAIScrapeAttemptShape
>

export type GenAIKBResponseUsage = 'dismissed' | 'ignored' | 'selected'

export const updateGenerativeAISettings = (data: {
  enable_pii_anonymization?: boolean
  matching_threshold?: number
  bedrock_content_matching_threshold?: number
  message_history_message_count?: number
  message_history_time_window?: number
  special_instructions?: string
  special_instructions_override?: boolean
  conversation_summary_prompt?: string
  suggested_reply_prompt?: string
  suggested_reply_prompt_sensitive?: string
  fuzzy_question_generation_prompt?: string
  use_tool_call?: boolean
  model?: string
  tool_call_features?: object
}) =>
  http2({
    url:
      '/api/v0/generative-text-service/institution/settings/update_genai_settings/',
    method: 'PUT',
    data,
    shape: GenerativeAISettingsShape,
  })

export const generativeTextService = {
  generateLiveChatResponse: ({
    contactId,
    smsLogId,
    cancelToken,
  }: {
    readonly contactId: string
    readonly smsLogId: string
    readonly cancelToken: CancelToken
  }) =>
    http2({
      url: `/api/v0/generative-text-service/generate/live-chat`,
      method: 'POST',
      data: {
        contact_id: contactId,
        sms_log_id: smsLogId,
      },
      cancelToken,
      shape: t.type({
        generative_response: t.string,
        transaction_id: t.union([t.undefined, t.number]),
        confident_in_answer: et.nullable(t.boolean),
      }),
    }),

  startGenerateLiveChatResponse: ({
    contactId,
    smsLogId,
  }: {
    readonly contactId: string
    readonly smsLogId: string
  }) =>
    http2({
      url: `/api/v0/generative-text-service/generate/live-chat/start`,
      method: 'POST',
      data: {
        contact_id: contactId,
        sms_log_id: smsLogId,
      },
      shape: t.type({
        task_id: t.string,
        status: t.string,
      }),
    }),

  pollGenerateLiveChatResponse: ({ taskId }: { readonly taskId: string }) =>
    http2({
      url: `/api/v0/generative-text-service/generate/live-chat/poll`,
      method: 'GET',
      params: {
        task_id: taskId,
      },
      shape: t.type({
        generative_response: et.nullable(t.string),
        transaction_id: et.nullable(t.number),
        confident_in_answer: et.nullable(t.boolean),
        embedded_sources: et.nullable(t.array(EmbeddedDataSource)),
        status: t.string,
      }),
    }),

  generateFuzzyQuestions: (params: { readonly understandingId: string }) =>
    http2({
      url: `/api/v0/generative-text-service/generate/fuzzy-questions`,
      method: 'POST',
      params: { ...params, write: true },
      shape: t.type({
        fuzzy_questions: t.array(t.type({ id: t.number, question: t.string })),
      }),
    }),

  getInstitutionSettings: () =>
    http2({
      url: '/api/v0/generative-text-service/institution/settings/',
      method: 'GET',
      shape: InstitutionGenerativeAISettingsShape,
    }),

  updateInstitutionPromptSetting: ({
    textPromptId,
  }: {
    readonly textPromptId: number
  }) =>
    http2({
      url: '/api/v0/generative-text-service/institution/settings/',
      method: 'POST',
      data: {
        text_prompt_id: textPromptId,
      },
      shape: t.unknown,
    }),

  listTransactions: ({
    action,
    page = 1,
    pageSize = 20,
    inResponseToSMSLogId,
    usedForResponseSMSLogId,
    generativeTextServiceTransactionId,
  }: {
    readonly action: GenerativeTextServiceTransactionAction
    readonly page: number
    readonly pageSize: number
    readonly inResponseToSMSLogId?: string
    readonly usedForResponseSMSLogId?: string
    readonly generativeTextServiceTransactionId?: string
  }) =>
    http2({
      url: `/api/v0/generative-text-service/transactions`,
      method: 'GET',
      params: {
        action,
        page,
        page_size: pageSize,
        in_response_to_sms_log_id: inResponseToSMSLogId,
        used_for_response_sms_log_id: usedForResponseSMSLogId,
        generative_text_service_transaction_id: generativeTextServiceTransactionId,
      },
      shape: t.type({
        results: t.array(GenerativeTextServiceTransactionShape),
        count: t.number,
      }),
    }),

  updateTransaction: ({
    transactionId,
    generateFromChat,
    responseUsage,
  }: {
    readonly transactionId: number
    readonly generateFromChat?: {
      readonly selectedCustomize?: boolean
      readonly usedForResponseSMSLogInFlightId?: string
    }
    readonly responseUsage?: GenAIKBResponseUsage
  }) => {
    const payload: {
      generate_from_chat?: {
        selected_customize?: boolean
        used_for_response_sms_log_in_flight_id?: string
      }
      kb_response_usage?: GenAIKBResponseUsage
    } = {}

    if (generateFromChat !== undefined) {
      payload['generate_from_chat'] = {
        selected_customize: generateFromChat.selectedCustomize,
        used_for_response_sms_log_in_flight_id:
          generateFromChat.usedForResponseSMSLogInFlightId,
      }
    }
    if (responseUsage !== undefined) {
      payload['kb_response_usage'] = responseUsage
    }
    return http2({
      url: `/api/v0/generative-text-service/transactions/${transactionId}/`,
      method: 'PATCH',
      data: payload,
      shape: t.type({
        ok: t.literal(true),
      }),
    })
  },

  scrape: {
    knowledgeSources: {
      list: (
        search: string | undefined,
        offset: number | undefined,
        cancelToken: CancelToken,
        statuses?: Array<string>,
        failureReason?: string,
        knowledgeSourceIds?: Array<string>
      ) =>
        http2({
          url: '/api/v0/generative-text-service/scrape-ai/knowledge-sources/',
          method: 'GET',
          params: {
            search,
            offset,
            statuses,
            failureReason,
            knowledgeSourceIds,
          },
          shape: t.type({
            count: t.number,
            previous: t.union([t.null, t.string]),
            next: t.union([t.null, t.string]),
            results: t.array(GenAIScrapeKnowledgeSourceShape),
          }),
          cancelToken,
        }),

      update: ({
        id,
        title,
        description,
      }: {
        readonly id: number
        readonly title?: string | null
        readonly description?: string | null
      }) =>
        http2({
          url: `/api/v0/generative-text-service/scrape-ai/knowledge-sources/${id}/`,
          method: 'PATCH',
          data: {
            title,
            description,
          },
          shape: GenAIScrapeKnowledgeSourceShape,
        }),

      bulkDelete: ({
        search,
        statuses,
        failureReason,
        knowledgeSourceIDs,
      }: {
        search?: string
        statuses?: Array<string>
        failureReason?: string
        knowledgeSourceIDs?: Array<number>
      }) =>
        http2({
          url:
            '/api/v0/generative-text-service/scrape-ai/knowledge-sources/bulk/',
          method: 'DELETE',
          params: { search },
          data: {
            knowledge_source_ids: knowledgeSourceIDs,
            statuses,
            failure_reason: failureReason,
          },
          shape: t.unknown,
        }),

      bulkCreate: ({
        knowledgeSources,
      }: {
        readonly knowledgeSources: GenAIScrapeKnowledgeSourceCreateReqShapeType[]
      }) =>
        http2({
          url:
            '/api/v0/generative-text-service/scrape-ai/knowledge-sources/bulk_create/',
          method: 'POST',
          data: knowledgeSources,
          shape: BulkScrapeKnowledgeSourceShape,
        }),
      crawl: ({ sourceURL }: { sourceURL: string }) =>
        http2({
          url:
            '/api/v0/generative-text-service/scrape-ai/knowledge-sources/crawl/',
          method: 'POST',
          data: {
            source_url: sourceURL,
          },
          shape: BulkScrapeKnowledgeSourceShape,
        }),

      scrapeDoc: ({ sourceURL }: { sourceURL: string }) =>
        http2({
          url:
            '/api/v0/generative-text-service/scrape-ai/knowledge-sources/scrape_doc/',
          method: 'POST',
          data: {
            source_url: sourceURL,
          },
          shape: GenAIScrapeKnowledgeSourceShape,
        }),
      rescrapeSources: ({
        search,
        statuses,
        failureReason,
        knowledgeSourceIDs,
      }: {
        search?: string
        statuses?: Array<string>
        failureReason?: string
        knowledgeSourceIDs?: Array<number>
      }) =>
        http2({
          url:
            '/api/v0/generative-text-service/scrape-ai/knowledge-sources/rescrape_sources/',
          method: 'POST',
          params: { search },
          data: {
            knowledge_source_ids: knowledgeSourceIDs,
            statuses,
            failure_reason: failureReason,
          },
          shape: t.array(GenAIScrapeKnowledgeSourceShape),
        }),
      getAllFailureMessages: () =>
        http2({
          url:
            '/api/v0/generative-text-service/scrape-ai/knowledge-sources/get_all_failure_messages/',
          method: 'GET',
          shape: t.array(t.string),
        }),
      ingestFile: ({
        file,
        fileName,
      }: {
        readonly file: File
        readonly fileName: string
      }) => {
        const data = new FormData()
        data.set('file', file)
        data.set('fileName', fileName)

        return http2({
          url:
            '/api/v0/generative-text-service/scrape-ai/knowledge-sources/ingest_file/',
          method: 'POST',
          data,
          shape: GenAIScrapeKnowledgeSourceShape,
        })
      },
      reuploadFile: ({
        file,
        fileName,
        knowledgeSourceId,
      }: {
        readonly file: File
        readonly fileName: string
        readonly knowledgeSourceId: number
      }) => {
        const data = new FormData()
        data.set('file', file)
        data.set('knowledgeSourceId', knowledgeSourceId.toString())
        data.set('fileName', fileName)

        return http2({
          url:
            '/api/v0/generative-text-service/scrape-ai/knowledge-sources/reupload_file/',
          method: 'POST',
          data,
          shape: GenAIScrapeKnowledgeSourceShape,
        })
      },

      downloadFile: ({
        knowledgeSourceId,
      }: {
        readonly knowledgeSourceId: number
      }) => {
        return http2({
          url:
            '/api/v0/generative-text-service/scrape-ai/knowledge-sources/download_file/',
          method: 'POST',
          data: {
            knowledge_source_id: knowledgeSourceId,
          },
          shape: t.type({ url: t.string }),
        })
      },
    },

    scrapeAttempts: {
      list: () =>
        http2({
          url: '/api/v0/generative-text-service/scrape-ai/scrape-attempts/',
          method: 'GET',
          shape: t.array(GenAIScrapeAttemptShape),
        }),
    },

    search: ({ query }: { readonly query: string }) =>
      http2({
        url: `/api/v0/generative-text-service/scrape-ai/internal_search/?query=${query}`,
        method: 'GET',
        shape: t.type({
          answer: t.string,
          system_message: t.array(t.string),
          transaction_id: t.number,
        }),
      }),

    getKBResponseFromScrapedContent: ({
      query,
      understandingId,
    }: {
      readonly query: string
      readonly understandingId: string
    }) => {
      const url = `/api/v0/generative-text-service/scrape-ai/internal_search/kb_response_from_scraped_content/`
      return http2({
        url,
        method: 'POST',
        data: {
          query,
          understanding_id: understandingId,
        },
        shape: t.type({
          answer: t.string,
          system_message: t.array(t.string),
          transaction_id: t.number,
          confident_in_answer: t.union([t.null, t.boolean]),
          embedded_sources: t.array(EmbeddedDataSource),
        }),
      })
    },
  },

  embedding: {
    getAnswerEmbeddingMetadata: ({ answerId }: { readonly answerId: number }) =>
      http2({
        url: `/api/v0/generative-text-service/embedding/answer/${answerId}/`,
        method: 'GET',
        shape: t.type({
          answer_embedding_metadata: t.union([
            t.null,
            t.type({
              id: t.number,
              answer_id: t.number,
              created: t.string,
              modified: t.string,
            }),
          ]),
        }),
      }),
  },
}

export const listCustomDashboards = () =>
  http2({
    url: '/api/v0/insights/dashboards',
    method: 'GET',
    shape: t.type({
      myDashboards: t.array(t.type({ name: t.string, share_id: t.string })),
      teamDashboards: t.array(t.type({ name: t.string, share_id: t.string })),
    }),
  })

export const getDashboard = (shareId?: string, editing?: boolean) =>
  http2({
    url: '/api/v0/insights/dashboards/get_dashboard/',
    params: { shareId, editing },
    method: 'GET',
    shape: DashboardResponseType,
  })

export const createOrUpdateDashboard = (
  data: DashboardResponse,
  shareId?: string | null
) =>
  http2({
    url: '/api/v0/insights/dashboards/create_or_update/',
    method: 'POST',
    data: { ...data, ...(shareId ? { share_id: shareId } : {}) },
    shape: DashboardResponseType,
  })

export const deleteDashboard = (shareId: string) =>
  http2({
    url: '/api/v0/insights/dashboards/' + shareId,
    method: 'DELETE',
    shape: t.unknown,
  })

export const downloadOrgAttributes = () =>
  http2({
    url: 'api/v0/org-attributes/download/',
    method: 'GET',
    shape: t.type({
      task_id: t.string,
    }),
  })

export const pollDownloadOrgAttributes = (taskId: string) =>
  http2({
    url: 'api/v0/org-attributes/poll_download/',
    method: 'GET',
    params: { task_id: taskId },
    shape: PollDownloadType,
  })

export const downloadImportErrorsCSVFile = (id: string) =>
  http2({
    url: `/api/v0/import-reports/${id}/download_error_file/`,
    method: 'GET',
    shape: t.type({ url: et.nullable(t.string) }),
  })

export const getKBTopicMatchCounts = (formFields: IUploadRequestData) => {
  const form = new FormData()
  Object.entries(formFields).forEach(
    ([key, value]: [string, string | File]) => {
      form.set(key, value)
    }
  )
  return http2({
    url: '/api/v0/upload-kb/get_kb_topic_match_counts/',
    method: 'POST',
    data: form,
    shape: t.type({
      upload_id: t.number,
      topic_counts: t.type({
        invalid: t.number,
        new: t.number,
        preexisting: t.number,
        total: t.number,
      }),
    }),
  })
}

export const uploadKnowledgeBaseCSV = (
  uploadId: number,
  replaceExistingAnswers: boolean
) => {
  const form = new FormData()
  form.set('replaceExistingAnswers', String(replaceExistingAnswers))
  form.set('uploadId', String(uploadId))
  return http2({
    url: '/api/v0/upload-kb/upload/',
    method: 'POST',
    data: form,
    shape: t.type({
      task_id: t.string,
      upload_id: t.number,
    }),
  })
}

export const pollUploadKnowledgeBaseCSV = ({
  taskId,
  uploadId,
}: {
  taskId: string
  uploadId?: number | string
}) => {
  return http2({
    url: '/api/v0/upload-kb/poll/',
    method: 'GET',
    params: {
      task_id: taskId,
      upload_id: uploadId,
    },
    shape: t.type({
      task_state: t.string,
      result: et.nullable(
        t.type({
          countCreated: t.number,
          countUpdated: t.number,
          countPartialErrors: t.number,
          countFullErrors: t.number,
          fileUrl: t.string,
        })
      ),
    }),
  })
}

export const rescheduleFailedCampaign = (id: string) => {
  return http2({
    url: `/api/v0/scheduled-messages/${id}/reschedule_failed_campaign/`,
    method: 'GET',
    shape: t.unknown,
  })
}

export const downloadScript = (id: string) =>
  http2({
    url: `/api/v0/dialogs/${id}/download_content/`,
    method: 'GET',
    shape: t.type({
      report_url: t.string,
    }),
  })

const uploadScriptResponseShape = t.type({
  task_state: t.string,
  error_file_path: t.union([t.string, t.null]),
  errors: t.array(
    t.type({
      row_num: t.number,
      detail: t.string,
      value: t.string,
    })
  ),
})

export type UploadScriptResponseType = t.TypeOf<
  typeof uploadScriptResponseShape
>

export const uploadScript = ({
  dialogId,
  file,
  fileName,
}: {
  dialogId: string
  file: File
  fileName: string
}) => {
  const data = new FormData()
  data.set('file', file)
  data.set('fileName', fileName)
  return http2({
    url: `/api/v0/dialogs/${dialogId}/upload_content/`,
    method: 'POST',
    data,
    shape: t.type({ task_id: t.string, s3_file_path: t.string }),
  })
}

export const pollUploadScript = ({
  dialogId,
  taskId,
  filePath,
}: {
  dialogId: string
  taskId: string
  filePath: string
}) => {
  return http2({
    url: `/api/v0/dialogs/${dialogId}/poll_upload/`,
    method: 'GET',
    params: {
      task_id: taskId,
      s3_file_path: filePath,
    },
    shape: uploadScriptResponseShape,
  })
}

const startPollConversationSummaryShape = t.type({
  task_id: t.string,
  status: t.string,
})
export const genConversationSummary = ({
  contactId,
}: {
  contactId: string
}) => {
  return http2({
    url: `/api/v0/generative-text-service/generate/conversation-summary`,
    method: 'POST',
    data: {
      contact_id: contactId,
    },
    shape: startPollConversationSummaryShape,
  })
}

const generateConversationSummaryShape = t.type({
  summary: et.nullable(t.string),
  timestamp: et.nullable(t.string),
  confident: et.nullable(t.boolean),
  status: t.string,
})

export const pollConversationSummary = ({ taskId }: { taskId: string }) => {
  return http2({
    url: `/api/v0/generative-text-service/generate/conversation-summary/poll`,
    method: 'GET',
    params: {
      task_id: taskId,
    },
    shape: generateConversationSummaryShape,
  })
}

export const listGenerativeTopics = () => {
  return http2({
    url: `/api/v0/generative-text-service/topics/`,
    method: 'GET',
    shape: t.array(
      t.type({
        id: t.number,
        name: t.string,
        prompt: t.string,
        include_past_smslogs: t.boolean,
        include_scraped_content: t.boolean,
        include_answer_content: t.boolean,
        include_org_attributes: t.boolean,
      })
    ),
  })
}

const UploadShape = t.type({
  id: t.number,
  college_id: t.string,
  bucket: t.string,
  key: t.string,
  completed: t.boolean,
  content_type: t.string,
  href: t.string,
  name: t.string,
  public: t.boolean,
  size: t.number,
})

const AttributeFieldShape = t.type({
  id: t.number,
  order: t.number,
  visible: t.boolean,
  required: t.boolean,
  display_field_name: et.nullable(t.string),
  top_level_field: et.nullable(t.string),
  default_field_value: et.nullable(t.string),
  contact_attribute: et.nullable(t.number),
})

const helloPagesPreferencesShape = t.type({
  id: t.number,
  page_title: t.string,
  url_path: t.string,
  created: t.string,
  modified: t.string,
  published: t.boolean,
  created_by: t.string,
  dialog_id: et.nullable(t.string),
  confirmation_message: t.string,
  icon: et.nullable(UploadShape),
  form_image: et.nullable(UploadShape),
  form_header: et.nullable(t.string),
  form_description: et.nullable(t.string),
  consent_text: t.string,
  attribute_fields: t.array(AttributeFieldShape),
})

const helloPagesListShape = t.type({
  id: t.number,
  modified: t.string,
  page_title: t.string,
  url_path: t.string,
  published: t.boolean,
  created_by: t.string,
  dialog_id: et.nullable(t.string),
  started: t.boolean,
})

export const getHelloPages = ({
  page,
  pageSize,
  sortBy,
  order,
}: {
  page: number
  pageSize: number
  sortBy?: string
  order?: string
}) => {
  return http2({
    url: '/api/v0/hello-page-preferences/',
    method: 'GET',
    params: {
      offset: (page - 1) * pageSize,
      sort_by: sortBy,
      sort_dir: order,
    },
    shape: t.type({
      next: et.nullable(t.string),
      previous: et.nullable(t.string),
      count: t.number,
      results: t.array(helloPagesListShape),
    }),
  })
}

export const retrieveHelloPage = ({ id }: { id: number }) => {
  return http2({
    url: `/api/v0/hello-page-preferences/${id}/`,
    method: 'GET',
    shape: helloPagesPreferencesShape,
  })
}

export const createHelloPage = ({ data }: { data: object }) => {
  return http2({
    url: `/api/v0/hello-page-preferences/`,
    method: 'POST',
    data,
    shape: helloPagesPreferencesShape,
  })
}

export const updateHelloPage = ({ id, data }: { id: number; data: object }) => {
  return http2({
    url: `/api/v0/hello-page-preferences/${id}/`,
    method: 'PATCH',
    data,
    shape: helloPagesPreferencesShape,
  })
}

export const deleteHelloPage = ({ id }: { id: number }) => {
  return http2({
    url: `/api/v0/hello-page-preferences/${id}/`,
    method: 'DELETE',
    shape: t.unknown,
  })
}

const helloPagesCampaignListShape = t.type({
  helloPagePreferenceId: t.number,
  name: t.string,
  distinctResponses: t.number,
  countEligibleUsersProcessed: t.number,
  isRespondable: t.boolean,
  lastSentDate: t.union([t.string, t.null]),
  dialogName: t.string,
  dialogId: t.string,
  urlPath: t.string,
  currentDialogId: et.nullable(t.string),
})

export const listHelloPageCampaigns = ({
  sortBy,
  order,
  query,
  page,
  pageSize,
}: {
  sortBy?: string
  order?: string
  query?: string
  page: number
  pageSize: number
}) =>
  http2({
    url: '/api/v0/hello-page-preferences/hello-page-campaigns/',
    method: 'GET',
    params: {
      sort_by: sortBy,
      sort_dir: order,
      offset: (page - 1) * pageSize,
      query,
    },
    shape: t.type({
      next: et.nullable(t.string),
      previous: et.nullable(t.string),
      count: t.number,
      results: t.array(helloPagesCampaignListShape),
    }),
  })

export const duplicateHelloPage = ({ id }: { id: number }) => {
  return http2({
    url: `/api/v0/hello-page-preferences/${id}/duplicate/`,
    method: 'GET',
    shape: helloPagesPreferencesShape,
  })
}

export const getAllHelloPageUrls = ({ id }: { id?: number }) => {
  return http2({
    url: `/api/v0/hello-page-preferences/get_all_urls/`,
    method: 'GET',
    params: id ? { id } : undefined,
    shape: t.array(t.string),
  })
}

const IntroCampaignVariantShape = t.type({
  name: t.string,
  scheduledAt: et.nullable(t.string),
  expirationInMins: t.number,
  urlPath: withFallback(t.string, ''),
  currentDialogId: et.nullable(t.string),
  engagementData: t.type({
    passiveUsersCount: t.number,
    activeUsersCount: t.number,
    priorSoftStopUsersCount: t.number,
    priorHardStopUsersCount: t.number,
    priorInvalidPhoneUsersCount: t.number,
    resultingSoftStopUsersCount: t.number,
    resultingHardStopUsersCount: t.number,
    messagedUsersCount: t.number,
    optOutUsersCount: t.number,
    deliveryFailureUsersCount: t.number,
    totalIntendedRecipients: t.number,
    totalEligibleRecipients: t.number,
    countEligibleUsersProcessed: t.number,
    totalRecipients: t.number,
    totalDistinctResponses: t.number,
  }),
  creationInfo: t.type({
    firstName: t.union([t.string, t.undefined]),
    lastName: t.union([t.string, t.undefined]),
    createdAt: t.union([t.string, t.undefined]),
  }),
  created: t.string,
  workflow: t.type({
    id: t.string,
    initialState: t.string,
    isInteractive: t.boolean,
    description: t.string,
    humanName: t.string,
  }),
})

const IntroCampaignVariantWithWorkflowShape = t.type({
  campaign: IntroCampaignVariantShape,
  workflow_steps: dialogStatesType,
})

export type GetIntroCampaignVariantDetailsType =
  | {
      webBotIdToken: string
      helloPageId?: number
      audience?: string
      dialogId?: string
    }
  | {
      webBotIdToken?: string
      helloPageId?: number
      audience?: string
      dialogId?: string
    }

export const getIntroCampaignVariantDetails = ({
  helloPageId,
  webBotIdToken,
  audience,
  dialogId,
}: GetIntroCampaignVariantDetailsType) => {
  const url = webBotIdToken
    ? `/api/v0/intro-dialogs/${webBotIdToken}/webchat-intro-campaigns/`
    : `/api/v0/hello-page-preferences/${helloPageId}/hello-page-campaigns/`
  return http2({
    url,
    method: 'GET',
    params: { audience, dialogId },
    shape: IntroCampaignVariantWithWorkflowShape,
  })
}

export const downloadContactAttributes = () =>
  http2({
    url: 'api/v0/contact-attributes/download/',
    method: 'GET',
    shape: t.type({
      task_id: t.string,
    }),
  })

export const pollDownloadContactAttributes = (taskId: string) =>
  http2({
    url: 'api/v0/contact-attributes/poll_download/',
    method: 'GET',
    params: { task_id: taskId },
    shape: PollDownloadType,
  })

export const addSynonym = (data: {
  synonymName: 'botname' | 'id' | 'institution'
  synonym: string
}) =>
  http2({
    url: '/api/v0/institution/current/add_synonym/',
    method: 'POST',
    data,
    shape: t.unknown,
  })

export const removeSynonym = (data: {
  synonymName: 'botname' | 'id' | 'institution'
  synonym: string
}) =>
  http2({
    url: '/api/v0/institution/current/remove_synonym/',
    method: 'POST',
    data,
    shape: t.unknown,
  })

export const createVCard = (data: IVCard) => {
  return http2({
    method: 'POST',
    url: '/api/v0/uploads/create_vcard',
    data,
    shape: t.type({
      url: t.string,
      fileName: t.string,
    }),
  })
}

const introCampaignListItemShape = t.type({
  webBotId: t.string,
  webBotName: t.string,
  distinctResponsesCount: t.number,
  countEligibleUsersProcessed: t.number,
  isRespondable: t.boolean,
  lastSentDate: et.nullable(t.string),
  dialogName: t.string,
  currentDialogId: t.string,
})
export type IntroCampaignListItemType = t.TypeOf<
  typeof introCampaignListItemShape
>

export const listWebchatIntroCampaigns = ({
  sortBy,
  order,
  query,
  page,
  pageSize,
}: {
  sortBy?: string
  order?: string
  query?: string
  page: number
  pageSize: number
}) =>
  http2({
    url: '/api/v0/intro-dialogs/webchat-intro-campaigns/',
    method: 'GET',
    params: {
      sort_by: sortBy,
      sort_dir: order,
      offset: (page - 1) * pageSize,
      query,
    },
    shape: t.type({
      next: et.nullable(t.string),
      previous: et.nullable(t.string),
      count: t.number,
      results: t.array(introCampaignListItemShape),
    }),
  })
